/* eslint-disable global-require */
const suport = {
  capes: require('./CAPES.png'),
  cnpq: require('./CNPq.png'),
  facepe: require('./Facepe.png'),
  google: require('./google.png'),
  paudalho: require('./paudalho.png'),
  ufpe: require('./UFPE.png'),
  freepik: require('./freepik.png'),
};

export default suport;
