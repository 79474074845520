import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { Creators as ExamActions } from '~/redux/ducks/exam';

const HemogramaData = ({ tempSecondStep, uploadData }) => (
  <div className="session">
    <span className="visualize-session-title">Dados do Hemograma</span>
    <Row className="marginL-5">
      <Col span={6}>
        <span className="visualize-title">Glóbulos vermelhos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.red_blood_cells ? `${tempSecondStep.red_blood_cells} milhões/mm³` : ' '}
        </span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">CHCM</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.mean_corpuscular_hemoglobin_concentration_mchc ? `${tempSecondStep.mean_corpuscular_hemoglobin_concentration_mchc} uL` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Mielócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.myelocytes ? `${tempSecondStep.myelocytes} %` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Linfócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.lymphocytes ? `${tempSecondStep.lymphocytes} %` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={6}>
        <span className="visualize-title">Hemoglobina</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.hemoglobin ? `${tempSecondStep.hemoglobin} g/dL` : ' '}
        </span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">RDW</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.red_blood_cell_distribution_width_rdw ? `${tempSecondStep.red_blood_cell_distribution_width_rdw}%` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Metamielócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.metamyelocytes ? `${tempSecondStep.metamyelocytes}%` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Monócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.monocytes ? `${tempSecondStep.monocytes}%` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={6}>
        <span className="visualize-title">Hematócrito</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.hematocrit ? `${tempSecondStep.hematocrit}%` : ' '}
        </span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">Leucócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.leukocytes ? `${tempSecondStep.leukocytes} mm³` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Segmentados</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.segmented ? `${tempSecondStep.segmented} %` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Neutrófilos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.neutrophils ? `${tempSecondStep.neutrophils} mm³` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={6}>
        <span className="visualize-title">VCM</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.mean_corpuscular_volume_mcv ? `${tempSecondStep.mean_corpuscular_volume_mcv} fL` : ' '}
        </span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">Mieloblastos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.myeloblasts ? `${tempSecondStep.myeloblasts}/µL` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Eosinófilos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.eosinophils ? `${tempSecondStep.eosinophils}%` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Plaquetas</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.platelets ? `${tempSecondStep.platelets}/µL` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={6}>
        <span className="visualize-title">HCM</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.mean_corpuscular_hemoglobin_mch ? `${tempSecondStep.mean_corpuscular_hemoglobin_mch} pg` : ' '}
        </span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">Promielócitos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.promyelocytes ? `${tempSecondStep.promyelocytes}%` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Basófilos</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.basophils ? `${tempSecondStep.basophils}/µL` : ' '}
        </span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Volume médio de plaquetas</span>
        <br />
        <span className="visualize-content">
          {tempSecondStep.mean_platelet_volume ? `${tempSecondStep.mean_platelet_volume}/µL` : ' '}
        </span>
      </Col>
    </Row>
  </div>
);

HemogramaData.propTypes = {
  tempSecondStep: PropTypes.object,
  uploadData: PropTypes.object,
};

HemogramaData.defaultProps = {
  tempSecondStep: {},
  uploadData: {}
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempSecondStep: state.exam.tempSecondStep,
  uploadData: state.exam.uploadData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HemogramaData);
