import React from 'react';
import PropTypes from 'prop-types';

function AboutCard({
  alt,
  key,
  src,
  size,
  body,
}) {
  return (
    <div className="about-card">
      <div className={`about-card-${size}`}>
        <img
          key={key}
          alt={alt}
          src={src}
          className="avatar"
        />
        <div style={{ width: size === 'sm' ? 320 : 'auto' }}>
          {body}
        </div>
      </div>
    </div>
  );
}

AboutCard.propTypes = {
  key: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  body: PropTypes.element,
};

AboutCard.defaultProps = {
  key: null,
  alt: '',
  src: null,
  size: 'lg',
  body: null,
};

export default AboutCard;
