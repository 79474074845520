import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['pt-BR'];
const availableLanguages = ['pt-BR', 'en'];

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng,
    lng: 'pt-BR',
    debug: false,
    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
