import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { NavLink } from 'react-router-dom';
import Image from '~/components/Image';
import MobileNav from '~/containers/TopNav/Components/MobileNav';
import StandardNav from '~/containers/TopNav/Components/StandardNav';

function TopNav({
  className,
  logo,
  menuItems,
  history,
  logged,
}) {
  const userLogged = (className === 'navbar-logged');

  function renderItem(item, index) {
    return (
      <Col span={2} key={index} className="navbar-items">
        <NavLink activeClassName="chosen" className="link" to={`${item[1]}`}>
          <span>{item[0]}</span>
        </NavLink>
      </Col>
    );
  }

  return (
    <nav className={`navbar ${className}`}>
      <Row justify="center" className="container-items">
        <Col flex="auto" className="image-logo logo pointer" onClick={() => history.push('/welcome')}>
          <Image
            src={logo}
            width="120"
            height="30"
          />
        </Col>
        <MobileNav
          userLogged={userLogged}
          menuItems={menuItems}
          renderItem={renderItem}
        />
        <StandardNav
          userLogged={userLogged}
          menuItems={menuItems}
          renderItem={renderItem}
          logged={logged}
        />
      </Row>
    </nav>
  );
}

TopNav.propTypes = {
  className: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  logged: PropTypes.bool,
};

TopNav.defaultProps = {
  history: [],
  logged: false,
};

export default (TopNav);
