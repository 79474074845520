/* eslint-disable @typescript-eslint/camelcase */
import { put, call } from 'redux-saga/effects';
import { Creators as ReportActions } from '../ducks/report';
import { api } from '~/services/api';
import Notification from '~/components/Notification'
import store from '~/redux/store';
import { push } from 'connected-react-router';

export function* report({ payload }) {
  try {
    const {
      doctor_name, doctor_professional_id, output_covid_result_id, remark,
    } = payload;


    const response = yield call(api.post, 'generate-medical-report', {
      doctor_name, doctor_professional_id, output_covid_result_id, remark,
    });

    yield put(ReportActions.reportDataSuccess(response.data));
  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao tentar salvar usuário.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(ReportActions.reportDataFailure(err));
  }
}
