import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Valter() {
  const body = (
    <div className="people">
      <p className="people-name">Valter Augusto de Freitas Barbosa, MSc</p>
      <p className="people-description">Engenheiro Biomédico (UFPE)</p>
      <p className="people-description">Mestre em Engenharia Biomédica (UFPE)</p>
      <p className="people-description"> Doutorando em Engenharia Mecânica (UFPE) </p>
    </div>
  );
  return (
    <AboutCard src={people.valter} body={body} size='mlg' />
  );
}
