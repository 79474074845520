import React, { useState } from 'react';
import {
  Select, DatePicker, Col, Row, Input, Button,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Creators as UserActions } from '~/redux/ducks/user';
import Notification from '~/components/Notification'
import MaskedInput from 'antd-mask-input'

const INVALID_ZIP_CODE = 'Não foi possível encontrar um endereço com esse CEP. Verifique o valor digitado.';

function Register({ userRegisterRequest }) {
  const { Option } = Select;

  const [firstName, setFirstName] = useState('');
  const [birthDate, setBirthDate] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [street, setStreet] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressAdjunct, setAddressAdjunct] = useState('');
  const [zipCode, setZipcode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phone, setPhone] = useState('');
  const [workplace, setWorkplace] = useState('');
  const [profission, setProfission] = useState('');
  const [professionID, setProfissionID] = useState('');
  const [genderNumber, setGenderNumber] = useState('');

  const registerRequest = () => {
    if( firstName &&
      birthDate &&
      email &&
      password &&
      street &&
      zipCode &&
      city &&
      state &&
      phone &&
      genderNumber != null) {
        userRegisterRequest({
          firstName,
          birthDate,
          email,
          password,
          street,
          addressNumber,
          addressAdjunct,
          zipCode,
          city,
          state,
          phone,
          workplace,
          profission,
          professionID,
          gender: genderNumber === 0 ? 'Feminino' : 'Masculino',
        });
      }else{
        Notification({title: 'Erro', description: 'Por favor, preencha todos os campos obrigatórios marcados com asterisco.', duration: 3, type: 'error'})
      }
  };

  const zipCodeChange = (value) => {
    if (!value) return;
    const CEP = value.replace(/\D/g, '');
    setZipcode(CEP);
    if (CEP.length === 8) {
      fetch(`https://viacep.com.br/ws/${CEP}/json/`)
        .then((response) => response.json())
        .then((json) => {
          if (json.erro) {
            setZipcode(null)
            setStreet('')
            setCity('')
            setAddressAdjunct('')
            setState('')
            Notification({title: 'Atenção!', description: INVALID_ZIP_CODE, duration: 5, type: 'warning'})
            return;
          }
          setStreet(json.logradouro ? `${json.logradouro}, ${json.bairro}` : '')
          setCity(json.localidade)
          setAddressAdjunct(json.complemento)
          setState(json.uf)
        });
    } else {
      setZipcode(null)
      setStreet('')
      setCity('')
      setAddressAdjunct('')
      setState('')
    }
  };

  return (
    <div className="form-exam">
      <div className="field">
        Nome completo *
        <Input
          placeholder=""
          style={{width: 422}}
          className="ant-input"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <Row>
      <Col span={12}>
          Profissão
          <input
            placeholder=""
            className="ant-input"
            value={profission}
            onChange={(inputValue) => setProfission(inputValue.target.value)}
          />
        </Col>
        <Col span={11} className='marginB-30 marginL-10'>
          Número do Conselho
          <input
            placeholder=""
            className="ant-input"
            value={professionID}
            onChange={(inputValue) => setProfissionID(inputValue.target.value)}
          />
        </Col>
        <Col span={12}>
          Local de Trabalho
          <input
            placeholder=""
            className="ant-input"
            value={workplace}
            onChange={(inputValue) => setWorkplace(inputValue.target.value)}
          />
        </Col>
        <Col span={11} className='marginL-10'>
          Telefone *
          <MaskedInput
            placeholder=""
            mask='(11)1 1111-1111'
            className="ant-input"
            value={phone}
            onChange={(inputValue) => setPhone(inputValue.target.value)}
          />
        </Col>
        <Col className="marginT-20" span={12}>
          Email *
          <input
            placeholder=""
            className="ant-input"
            value={email}
            onChange={(inputValue) => setEmail(inputValue.target.value)}
          />
        </Col>
        <Col className="marginT-20 marginL-10" span={11}>
          Password *
          <input
            placeholder=""
            className="ant-input"
            value={password}
            type="password"
            onChange={(inputValue) => setPassword(inputValue.target.value)}
          />
        </Col>
      </Row>
      <Row className="marginT-30">
        <Col span={12}>
          <div className="field">
            Sexo *
            <Select
              defaultValue="Selecione"
              style={{ width: 120 }}
              value={genderNumber}
              onChange={(item) => setGenderNumber(item)}
            >
              <Option value={0}>Feminimo</Option>
              <Option value={1}>Masculino</Option>
            </Select>
          </div>
        </Col>
        <Col span={12}>
        Data de nascimento *
          <DatePicker
            minDate={new Date()}
            placeholder="DD/MM/AAAA"
            format="L"
            className="small-input"
            onChange={(item) => setBirthDate(item)}
            value={birthDate}
          />
        </Col>
      </Row>

      <Row>
        <div className="field">
        CEP *
        <MaskedInput
            placeholder=""
            mask='11111-111'
            className="ant-input"
            value={zipCode}
            onChange={(inputValue) => setZipcode(inputValue.target.value)}
            onBlur={(inputVal) => zipCodeChange(inputVal.target.value)}
          />
        </div>
        <Col span={19}>
          <div className="field">
        Cidade *
            <Input
              placeholder=""
              className="ant-input md-input"
              readOnly
              value={city}
              onChange={(inputValue) => setCity(inputValue.target.value)}
            />
          </div>
        </Col>
        <Col span={5}>
          <div className="field marginL-20">
            <span>UF *</span>
            <Input
              placeholder=""
              className="ant-input"
              readOnly
              value={state}
              onChange={(inputValue) => setState(inputValue.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={19}>
          <div className="field">
          Endereço *
          <input
            placeholder=""
            className="ant-input"
            value={street}
            onChange={(inputValue) => setStreet(inputValue.target.value)}
          />
        </div>
        </Col>
        <Col span={5}>
          <div className="field marginL-10">
            <span>Num.</span>
            <Input
              placeholder=""
              type='number'
              className="ant-input"
              value={addressNumber}
            onChange={(inputValue) => setAddressNumber(inputValue.target.value)}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="field">
            <span>Complemento</span>
            <input
              placeholder=""
              className="ant-input"
              value={addressAdjunct}
              onChange={(inputValue) => setAddressAdjunct(inputValue.target.value)}
            />
          </div>
        </Col>
      </Row>
      
      <Button style={{ marginLeft: 110 }} type="default" className="btn-default" onClick={() => registerRequest()}>
          Cadastrar
      </Button>
    </div>
  );
}

Register.propTypes = {
  userRegisterRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
