import { put, call } from 'redux-saga/effects';
import { Creators as ConsultActions } from '../ducks/consult';
import { api } from '~/services/api';
import Notification from '~/components/Notification'
import store from '~/redux/store';
import { push } from 'connected-react-router';

export function* historic() {
  try {
    const response = yield call(api.get, 'user/output-covid-results');

    yield put(ConsultActions.historicDataSuccess(response.data));
  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao tentar salvar usuário.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(ConsultActions.historicDataFailure(err));
  }
}
