import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  Row, Col, Button
} from 'antd';
import { Creators as ReportActions } from '~/redux/ducks/report';
import {
  LoadingOutlined,
} from '@ant-design/icons';

function printReport() {
  window.print();
}

const Laudo = ({ data, loading }) => {
  return( 
    <div id='laudo' className='visualize-laudo'>
     {loading ? (<LoadingOutlined spin />) : (
       data && (
        <div>
          <div id='printable-report'>
          <div className="session">
            <span className="visualize-session-title">Informações Gerais</span>
            <Row className="marginL-5">
              <Col span={12}>
                <span className="visualize-title">Nome do Paciente</span>
                <br />
                <span className="visualize-content">{(data.patient && data.patient.first_name) ? data.patient.first_name : ''}</span>
              </Col>
              <Col span={12} className="">
                <span className="visualize-title">ID do Paciente</span>
                <br />
                <span className="visualize-content">{(data.patient && data.patient.patient_id) ? data.patient.patient_id : ''}</span>
              </Col>
            </Row>
            <Row className="marginL-5 marginT-20">
              <Col span={12}>
                <span className="visualize-title">Médico Solicitante</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.doctor_name) ? data.medical_report.doctor_name : ''}</span>
              </Col>
              <Col span={12} className="">
                <span className="visualize-title">Registro no conselho</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.doctor_professional_id) ? data.medical_report.doctor_professional_id : ''}</span>
              </Col>
            </Row>
            <Row className="marginL-5 marginT-20">
              <Col span={12}>
                <span className="visualize-title">Data</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.generated_at) ? moment(data.medical_report.generated_at).format('DD/MM/YYYY') : ''}</span>
              </Col>
              <Col span={12} className="">
                <span className="visualize-title">Hora</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.generated_at) ? moment(data.medical_report.generated_at).format('HH:MM') : ''}</span>
              </Col>
            </Row>
        </div>
        <div className="session">
            <span className="visualize-session-title">Análise de estimativa de diagnóstico da COVID-19</span>
            <Row className="marginL-5">
              <Col span={24}>
                <span className="visualize-title">Diagnóstico</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.output_covid_result) ? data.medical_report.output_covid_result ? 'Positivo' : 'Negativo' : ''}</span>
              </Col>
            </Row>
        </div>
        <div className="session">
            <span className="visualize-session-title">Observações do técnico responsável</span>
            <Row className="marginL-5">
              <Col span={24}>
                <span className="visualize-title">Observações</span>
                <br />
                <span className="visualize-content">{(data.medical_report && data.medical_report.remark) ? data.medical_report.remark : 'Nenhuma observação'}</span>
              </Col>
            </Row>
        </div>
        <div id='signature' className='signature-wrapper'>           
          <div className='signature-data' id='signature-data'>
            <div className='data'>
              <br/>
              <span style={{textAlign: 'center', alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}}>
                {(data.medical_report && data.medical_report.doctor_name) ? data.medical_report.doctor_name : ''}
              </span>
              <br/>
              <span style={{textAlign: 'center'}}>
                {(data.medical_report && data.medical_report.doctor_professional_id) ? data.medical_report.doctor_professional_id : ''}
              </span>
              <br/>
              <span style={{textAlign: 'center'}}>
                {(data.laudist && data.laudist.city) ? `${data.laudist.city}, ${data.laudist.state}` : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
        <Row className="btn-result-laudo" id='btn-result-laudo'>
          <Button type="default" className="btn-default" onClick={() => printReport()}>
          Imprimir
          </Button>
        </Row>
      </div>
       )
      )}
    </div>
)};

Laudo.propTypes = {
  data: PropTypes.object,
};

Laudo.defaultProps = {
  data: {},
};

const mapStateToProps = (state) => ({
  loading: state.report.loading,
  data: state.report.data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ReportActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Laudo);
