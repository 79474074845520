/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Divider, Drawer } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from '~/components/Image';
import BurgerIconWhite from '~/assets/img/burger-menu-white.svg';
import BurgerIconRed from '~/assets/img/burger-menu-red.svg';

function MobileNav({
  userLogged,
  menuItems,
  renderItem,
}) {
  const [mobileMenu, setMobileMenu] = useState(false);

  function toggleMobileMenu() {
    setMobileMenu(!mobileMenu);
  }

  function renderDivider() {
    if (userLogged) {
      return (<Divider orientation="center" className="nav-divider-label" />);
    }
    return (<Divider orientation="center" className="nav-divider-label">login</Divider>);
  }

  return (
    <div className="navbar-responsive">
      <div className="navbar-burger-icon" onClick={toggleMobileMenu}>
        <Image
          src={userLogged ? BurgerIconRed : BurgerIconWhite}
          height={30}
          width={30}
        />
      </div>
      <Drawer
        placement="top"
        height="100%"
        closable={false}
        onClose={toggleMobileMenu}
        visible={mobileMenu}
        className="navbar-responsive-menu"
      >
        <div className="menu-content">
          {menuItems.map((item, index) => (renderItem(item, index)))}
        </div>
        <div className="nav-divider-container">
          { renderDivider() }
        </div>
      </Drawer>
    </div>
  );
}

MobileNav.propTypes = {
  userLogged: PropTypes.bool.isRequired,
  renderItem: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
};

export default (MobileNav);
