import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

const PageHeader = ({
  title, subtitle, justify, isLogin,
}) => (
  <div className="page-header">
    <Row className={`title-container text-${justify}`}>
      {isLogin ? (
        <div>
Olá,
          <br />
Bem vindo de volta!
        </div>
      ) : title}
    </Row>
    <div className={`separator ${justify}`}>&nbsp;</div>
    <Row className={`subtitle-container text-${justify}`}>
      {isLogin ? (
        <div>
Por favor, insira seus dados para
          <br />
          efetuar o login.
        </div>
      ) : subtitle}

    </Row>
  </div>
);

PageHeader.defaultProps = {
  justify: 'left',
};

PageHeader.propTypes = {
  title: PropTypes.string,
  isLogin: PropTypes.bool,
  subtitle: PropTypes.string,
  justify: PropTypes.string,
};

PageHeader.defaultProps = {
  title: '',
  subtitle: '',
  justify: 'left',
  isLogin: false,
};

export default PageHeader;
