import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Home from './home';
import Exam from './exam';
import Historic from './historic';
import Result from './result';
import Register from './register';
import Report from './report';

import { Creators as SessionActions } from '~/redux/ducks/session';

const Routes = ({ match }) => {
  if (!localStorage.getItem('@SESSION')) {
    return (<Redirect to="/welcome" />);
  }
  return (
    <main>
      <Switch>
        <Route path={`${match.url}/home`} component={Home} />
        <Route path={`${match.url}/exam`} component={Exam} />
        <Route path={`${match.url}/historic`} component={Historic} />
        <Route path={`${match.url}/result`} component={Result} />
        <Route path={`${match.url}/report`} component={Report} />
        <Route path={`${match.url}/register`} component={Register} />
        <Redirect to="/error" />
      </Switch>
    </main>
  );
};

Routes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(SessionActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
