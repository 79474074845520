import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Anderson() {
  const body = (
    <div className="people">
      <p className="people-name">Anderson Felix da Silva</p>
      <p className="people-description">Engenheiro da Computação (UFPE)</p>
    </div>
  );
  return (
    <AboutCard src={people.anderson} body={body} size="xs" />
  );
}
