import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Button,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import PageHeader from '~/components/PageHeader';
import Covid from './components/covid';
import ICUHops from './components/icu-hops';
import RegularHosp from './components/regular-hosp';
import SemiICU from './components/semi-icu';
import TopNav from '~~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import { Creators as ExamActions } from '~/redux/ducks/exam';

const Result = ({
  history, examResult, loading,
}) => {
  const [menuItems] = useState([
    ['Início', '/app/home'],
    ['Exame', '/app/exam'],
    ['Resultado', '/app/result'],
    ['Histórico', '/app/historic'],
  ]);

  function printData() {
    window.print();
  }
  return (
    <>
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div className="visualize-result">
        {loading && (<LoadingOutlined spin />)}
        {(examResult.result) && (
          <>
            <div id="printableArea">
              <PageHeader title="Resultado" />
              <Covid />
              <RegularHosp />
              <SemiICU />
              <ICUHops />
            </div>
            <div className="btn-result">
              <Button type="default" className="btn-default" onClick={() => printData()}>
              Imprimir
              </Button>
            </div>
          </>
        )}
      </div>
      <Row className="footer-exam">
        <PageFooter />
      </Row>
    </>
  );
};

Result.propTypes = {
  examResult: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

Result.defaultProps = {
  history: [],
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  examResult: state.exam.examResult,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Result);
