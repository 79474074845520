import { put, call } from 'redux-saga/effects';
import { Creators as UserActions } from '../ducks/user';
import { api } from '~/services/api';
import moment from 'moment';
import Notification from '~/components/Notification'
import store from '~/redux/store';
import { push } from 'connected-react-router';
import { getOnlyNumbers } from '~/util/stringUtils'

export function* userRegister({ payload }) {
  try {
   
    const {
      firstName,
      birthDate,
      email,
      password,
      street,
      addressNumber,
      addressAdjunct,
      zipCode,
      city,
      state,
      phone,
      workplace,
      profission,
      professionID,
      gender,
    } = payload;

    const response = yield call(api.post, 'user', {
      first_name: firstName,
      last_name: '',
      birth_date: moment(birthDate).format('YYYY/MM/DD'),
      type: 'laudist',
      workplace,
      password,
      email,
      street,
      address_number: addressNumber,
      address_adjunct: addressAdjunct,
      zip_code: getOnlyNumbers(zipCode),
      city,
      state,
      country: 'Brazil',
      mobile: getOnlyNumbers(phone),
      profession: profission,
      professional_id: professionID,
      gender,
    });
    
    Notification({title: 'Sucesso!', description: `O usuário ${firstName} foi criado e já pode logar com o email e senha cadastrados`, duration: 3, type: 'success'})
    yield put(UserActions.userRegisterSuccess(response.data));
    store.dispatch(push('/app/home'));
  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao tentar salvar usuário.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(UserActions.userRegisterFailure(err));
  }
}
