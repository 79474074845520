import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Row,
} from 'antd';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';

function WelcomePage({
  t, history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/welcome'],
    [`${t('login')}`, '/login'],
    ['Parceiros', '/consult'],
    [`${t('about')}`, '/about'],
  ]);

  return (
    <div className="welcome">
      <div className="fixed-background" />
      <main>
        <div className="container-fluid">
          <TopNav
            className="navbar-non-logged"
            logo={Logo}
            menuItems={menuItems}
            history={history}
          />
        </div>
      </main>
      <div className="content">
        <p className="title">
          Otimizando o seu processo de diagnóstico
        </p>
        <p className="subtitle">
          Heg.IA é um sistema inteligente capaz de apoiar o diagnóstico da Covid-19 e predizer o tipo de internação indicada para o paciente, em caso de resultados positivos, baseado em exames de sangue.
        </p>
        <Row className="btn_wrapper">
          <Button className="btn-default" onClick={() => history.push('/login')}>Login</Button>
        </Row>
      </div>
      <PageFooter />
    </div>
  );
}

WelcomePage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

WelcomePage.defaultProps = {
  history: [],
};

export default withTranslation()(WelcomePage);
