import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import {
  Wellington,
  Ricardo,
  Juliana,
  Valter,
  Maira,
  Raquel,
  Rodrigo,
  Karina,
  Jenifer,
  David,
  Reiga,
  Claudio,
  Anderson,
  Thifany,
  Maria,
  Gabriel,
  Jose,
  Vitoria,
  Luiz,
  Clarice
} from './team';
import PageHeader from '~/components/PageHeader';
import UFPE from '~/assets/img/UFPE.png';
import DEBM from '~/assets/img/DEBM.png';
import Image from '~/components/Image';
function About({
  t,
  history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/welcome'],
    [`${t('login')}`, '/login'],
    ['Parceiros', '/consult'],
    [`${t('about')}`, '/about'],
  ]);

  return (
    <>
      <TopNav
        className="navbar-logged"
        logo={Logo}
        menuItems={menuItems}
        history={history}
      />
      <div className="column-centered">
        <div style={{ marginLeft: 60, marginTop: 30 }}>
          <PageHeader title="Quem somos?" />
        </div>
        <div className="container-about mb-40">
          <Wellington />
          <Ricardo />
        </div>
        <div className="container-about">
          <Luiz />
        </div>
        <span className="title-role">Time de especialistas</span>
        <div className="container-about mb-40">
          <Juliana />
          <Valter />
        </div>
        <div className="container-about">
          <Maira />
          <Clarice />
        </div>
        <span className="title-role">Time de sistemas</span>
        <div className="container-about">
          <Raquel />
          <Rodrigo />
          <Claudio />
        </div>
        <span className="title-role">Colaboradores</span>
        <div className="container-about mb-40">
          <David />
          <Reiga />
          <Karina />
        </div>
        <div className="container-about mb-40">
          <Jenifer />
          <Thifany />
          <Maria />
        </div>
        <div className="container-about mb-40">
          <Anderson />
          <Jose />
          <Vitoria />
        </div>
        <div className="container-about mb-40">
          <div className="container-non-centered">
            <div style={{marginLeft: -30}}>
              <Gabriel />
            </div>
            <div style={{display: 'flex', marginTop:60, marginLeft: 400, alignItems: 'center', justifyContent: 'space-betwenn'}}>
              <Image src={UFPE} width="200" height="100" className="image" />
              <Image src={DEBM} width="150" height="60" className="image debm" />   
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

About.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

About.defaultProps = {
  history: [],
};

export default withTranslation()(About);
