import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Luiz() {
  const body = (
    <div className="people">
      <p className="people-name">Prof. Dr. Luiz Alberto Reis Mattos Júnior</p>
      <p className="people-role">Pesquisador associado</p>
      <p className="people-description">Docente do Departamento de Medicina Clínica da UFPE.</p>
      <p className="people-description"> Diretor do Hospital das Clínicas de Pernambuco</p>
      <p className="people-description"> Médico (UFPE) </p>
      <p className="people-description"> Mestre em Oncologia (UFPE) </p>
      <p className="people-description"> Doutor em Biologia Aplicada à Saúde (UFPE) </p>
    </div>
  );
  return (
    <AboutCard src={people.luiz} body={body} />
  );
}
