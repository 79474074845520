export const stripRightNonNumeric = (string) => {
  if (!string) return '';

  const lastChar = string.slice(-1);
  return Number.isNaN(Number(lastChar)) || lastChar === ' '
    ? stripRightNonNumeric(string.slice(0, -1))
    : string;
};

export const removeAllNonNumeric = (string) => (
  string
    ? string.replace(/\D/g, '') || ''
    : ''
);

const onlyNumbersRegex = /\D+/g

export const getOnlyNumbers = (value) => {
  if (!value && value !== '') return null
  return value.replace(onlyNumbersRegex, '')
}

