import axios from 'axios';
import { apiUrl } from '~/constants/defaultValues';
import store from '~/redux/store';

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.defaults.timeout = 4000;

const requestMiddleware = (config) => {
  const { session } = store.getState();
  if (!config.url.endsWith('login') && session.accessToken) {
    const configWithToken = { ...config };
    configWithToken.headers.common.Authorization = `Bearer ${session.accessToken}`;
    delete configWithToken.Authorization;
    return configWithToken;
  }
  return config;
};

const responseMiddleware = (response) => Promise.resolve(response);

api.interceptors.request.use(requestMiddleware);
api.interceptors.response.use(responseMiddleware);

export {
  api,
};

