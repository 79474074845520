import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Karina() {
  const body = (
    <div className="people">
      <p className="people-name">Karina Cruz Campista</p>
      <p className="people-description">Engenheira Biomédica (UFABC)</p>
      <p className="people-description">possui título de MBA em Gestão internacional (Université Paris-Sorbonne & Paris-Dauphine).</p>
    </div>
  );
  return (
    <AboutCard src={people.karina} body={body} size="sm" />
  );
}
