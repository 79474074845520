import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Jenifer() {
  const body = (
    <div className="people">
      <p className="people-name">Jenifer Emídio de Almeida Albuquerque, MSc</p>
      <p className="people-description">Enfermeira (UNINASSAU)</p>
      <p className="people-description">Mestra em  Engenharia Biomédica (UFPE).</p>
    </div>
  );
  return (
    <AboutCard src={people.jenifer} body={body} size="xs" />
  );
}
