export const Types = {
  USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
  USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export default function User(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.USER_REGISTER_REQUEST:
      return { ...state, loading: true };
    case Types.USER_REGISTER_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.USER_REGISTER_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  userRegisterRequest: (payload) => ({
    type: Types.USER_REGISTER_REQUEST,
    payload,
  }),

  userRegisterSuccess: (payload) => ({
    type: Types.USER_REGISTER_SUCCESS,
    payload,
  }),

  userRegisterFailure: (reason) => ({
    type: Types.USER_REGISTER_FAILURE,
    payload: reason,
  }),
};
