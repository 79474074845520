import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Thifany() {
  const body = (
    <div className="people">
      <p className="people-name">Thifany Ketuli Silva de Souza</p>
      <p className="people-description">Estudante de Engenharia Biomédica (UFPE)</p>
    </div>
  );
  return (
    <AboutCard src={people.thifany} body={body} size="xs" />
  );
}
