/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import {
  Row, Col,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Creators as ExamActions } from '~/redux/ducks/exam';

function FourthStep({ fourthStepRequest, tempFourthStep }) {
  const [hb_saturation_arterial_blood_gases, sethb_saturation_arterial_blood_gases] = useState(tempFourthStep.hb_saturation_arterial_blood_gases);
  const [p_o2_arterial_blood_gas_analysis, setp_o2_arterial_blood_gas_analysis] = useState(tempFourthStep.p_o2_arterial_blood_gas_analysis);
  const [p_co2_arterial_blood_gas_analysis, setp_co2_arterial_blood_gas_analysis] = useState(tempFourthStep.p_co2_arterial_blood_gas_analysis);
  const [total_co2_arterial_blood_gas_analysis, settotal_co2_arterial_blood_gas_analysis] = useState(tempFourthStep.total_co2_arterial_blood_gas_analysis);
  const [hco3_arterial_blood_gas_analysis, sethco3_arterial_blood_gas_analysis] = useState(tempFourthStep.hco3_arterial_blood_gas_analysis);
  const [p_h_arterial_blood_gas_analysis, setp_h_arterial_blood_gas_analysis] = useState(tempFourthStep.p_h_arterial_blood_gas_analysis);
  const [base_excess_arterial_blood_gas_analysis, setbase_excess_arterial_blood_gas_analysis] = useState(tempFourthStep.base_excess_arterial_blood_gas_analysis);
  const [arteiral_fio2, setarteiral_fio2] = useState(tempFourthStep.arteiral_fio2);
  const [ct_o2_arterial_blood_gas_analysis, setct_o2_arterial_blood_gas_analysis] = useState(tempFourthStep.ct_o2_arterial_blood_gas_analysis);

  useEffect(() => {
    fourthStepRequest({
      hb_saturation_arterial_blood_gases,
      p_o2_arterial_blood_gas_analysis,
      p_co2_arterial_blood_gas_analysis,
      total_co2_arterial_blood_gas_analysis,
      hco3_arterial_blood_gas_analysis,
      p_h_arterial_blood_gas_analysis,
      base_excess_arterial_blood_gas_analysis,
      arteiral_fio2,
      ct_o2_arterial_blood_gas_analysis,
    });
  }, [
    hb_saturation_arterial_blood_gases,
    p_o2_arterial_blood_gas_analysis,
    p_co2_arterial_blood_gas_analysis,
    total_co2_arterial_blood_gas_analysis,
    hco3_arterial_blood_gas_analysis,
    p_h_arterial_blood_gas_analysis,
    base_excess_arterial_blood_gas_analysis,
    arteiral_fio2,
    ct_o2_arterial_blood_gas_analysis,
    fourthStepRequest]);


  return (
    <div className="step-form">
      <Row>
        <Col span={16}>
          <Row>
            <span className="exam-title">GASOMETRIA</span>
          </Row>
          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">SATURAÇÃO DE HEMOGLOBINA ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={hb_saturation_arterial_blood_gases}
                onChange={(e) => sethb_saturation_arterial_blood_gases(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mEc/L</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">PRESSÃO DE O2 NO SANGUE ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={p_o2_arterial_blood_gas_analysis}
                onChange={(e) => setp_o2_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mmHg</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">PRESSÃO PARCIAL DE CO2:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={p_co2_arterial_blood_gas_analysis}
                onChange={(e) => setp_co2_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mmHg</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">CO2 TOTAL NO SANGUE ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={total_co2_arterial_blood_gas_analysis}
                onChange={(e) => settotal_co2_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mmHg</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">HCO3 ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={hco3_arterial_blood_gas_analysis}
                onChange={(e) => sethco3_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mEc/L</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">ANÁLISE DO PH DO SANGUE ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={p_h_arterial_blood_gas_analysis}
                onChange={(e) => setp_h_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text" />
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">EXCESSO DE BASE (BE): </span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={base_excess_arterial_blood_gas_analysis}
                onChange={(e) => setbase_excess_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mEq/L</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">FRAÇÃO DE O2 INSPIRADO:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={arteiral_fio2}
                onChange={(e) => setarteiral_fio2(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">%</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">CONCENTRAÇÃO TOTAL DE O2 ARTERIAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={ct_o2_arterial_blood_gas_analysis}
                onChange={(e) => setct_o2_arterial_blood_gas_analysis(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">%</span>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <span className="exam-title">VALORES DE REFERÊNCIA</span>
          </Row>
          <Row style={{marginTop: 32}}>
            <span className="ref-text">-2 - 2</span>
          </Row>

          <Row className="marginT-65">
            <span className="ref-text">80 - 100</span>
          </Row>

          <Row style={{marginTop: 60}}>
            <span className="ref-text">35 - 45</span>
          </Row>

          <Row style={{marginTop: 40}}>
            <span className="ref-text">35 - 45</span>
          </Row>

          <Row className="marginT-60">
            <span className="ref-text">22 - 26</span>
          </Row>

          <Row style={{marginTop: 40}}>
            <span className="ref-text">7,35 - 7,45</span>
          </Row>

          <Row style={{marginTop: 58}}>
            <span className="ref-text">-3 - 3</span>
          </Row>

          <Row className="marginT-40">
            <span className="ref-text">95 - 100</span>
          </Row>

          <Row className="marginT-43">
            <span className="ref-text">92 - 100</span>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

FourthStep.propTypes = {
  fourthStepRequest: PropTypes.func.isRequired,
  tempFourthStep: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempFourthStep: state.exam.tempFourthStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FourthStep);
