import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { api } from '~/services/api';
import { Creators as SessionActions } from '../ducks/session';
import Notification from '~/components/Notification'

export function* login(action) {
  try {
    const response = yield call(api.post, 'login', action.payload);

    const session = {
      accessToken: response.data.access_token,
      user_type: response.data.user.type,
      uid: response.data.user.id,
      tokenType: 'Bearer',
    };

    localStorage.setItem('@SESSION', JSON.stringify({ ...session, user: response.data.user }));
    localStorage.setItem('user_uid', session.uid);
    localStorage.setItem('username', response.data.user.first_name);
    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('usertype', response.data.user.type);

    yield put(SessionActions.loginSuccess(response.data.user, session));

    yield put(push('/'));
  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro no servidor! Falha ao tentar fazer login.';
    
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(SessionActions.loginFailure());
  }
}

export function* userInfo(userId) {
  try {
    const response = yield call(api.post, 'user', { userId });

    yield put(SessionActions.userSuccess(response.data));
  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro no servidor! Falha ao tentar obter dados do usuário.';
    
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(SessionActions.userFailure(err));
  }
}

export function* clearSessions({ payload: id }) {
  try {
    yield call(api.delete, `admin/users/${id}/clear_sessions`);
  } catch (err) {
    const message = err.response && err.response.data && err.response.data.msg !== '' 
    ? err.response.data.msg 
    : 'Falha ao limpar sessão do usuário.';
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})  }
}

export function* logout() {
  try {
    localStorage.removeItem('@SESSION');
    localStorage.removeItem('user_uid');

    yield put(SessionActions.logoutSuccess());

    yield put(push('/login'));
  } catch (err) {
      Notification({title: 'Erro', description: 'Erro ao tentar fazer logout', duration: 3, type: 'error'})  }
  }
