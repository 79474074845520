/* eslint-disable @typescript-eslint/camelcase */
import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import { api } from '~/services/api';
import { Creators as ExamActions } from '../ducks/exam';
import moment from 'moment';
import Notification from '~/components/Notification'
import store from '~/redux/store';
import { push } from 'connected-react-router';
import { getOnlyNumbers } from '~/util/stringUtils'

export function* cadPatient(params) {
  try {
    const {
      firstName,
      patientID,
      phone,
      gender,
      birthDate,
      zipCode,
      city,
      state,
      street,
      addressNumber,
      addressAdjunct,
    } = params.payload;


    const response = yield call(api.post, 'user', {
      first_name: firstName,
      last_name: '',
      birth_date: moment(birthDate).format('YYYY/MM/DD'),
      type: 'patient',
      patient_id: patientID,
      street,
      zip_code: getOnlyNumbers(zipCode),
      city,
      state,
      country: 'Brazil',
      mobile: getOnlyNumbers(phone),
      gender,
      address_number: addressNumber,
      address_adjunct: addressAdjunct
    });

    yield put(ExamActions.cadPatientSuccess(response.data));
  } catch (err) {
    const message = err.response && err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao tentar salvar paciente.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(ExamActions.cadPatientFailure(err));
  }
}

export function* makeExam(params) {
  try {
    const {
      tempFirstStep, tempSecondStep, tempThirdStep, tempFourthStep,
    } = params.payload;

    const { patientID } = tempFirstStep;
    const {
      red_blood_cells,
      hemoglobin,
      hematocrit,
      mean_corpuscular_volume_mcv,
      mean_corpuscular_hemoglobin_mch,
      mean_corpuscular_hemoglobin_concentration_mchc,
      red_blood_cell_distribution_width_rdw,
      leukocytes,
      myeloblasts,
      promyelocytes,
      myelocytes,
      metamyelocytes,
      segmented,
      eosinophils,
      basophils,
      lymphocytes,
      monocytes,
      neutrophils,
      platelets,
      mean_platelet_volume,
    } = tempSecondStep;

    const {
      direct_bilirubin,
      indirect_bilirubin,
      total_bilirubin,
      serum_glucose,
      lipase_dosage,
      urea,
      partial_thromboplastin_time_ptt,
      d_dimer,
      lactic_dehydrogenase,
      prothrombin_time_pt_activity,
      creatinine,
      proteina_c_reativa_mgdl
    } = tempThirdStep;

    const {
      hb_saturation_arterial_blood_gases,
      p_o2_arterial_blood_gas_analysis,
      p_co2_arterial_blood_gas_analysis,
      total_co2_arterial_blood_gas_analysis,
      hco3_arterial_blood_gas_analysis,
      p_h_arterial_blood_gas_analysis,
      base_excess_arterial_blood_gas_analysis,
      arteiral_fio2,
      ct_o2_arterial_blood_gas_analysis,
    } = tempFourthStep;

    const response = yield call(api.post, `user/${patientID}/blood-tests-result`, {
      red_blood_cells: parseFloat(red_blood_cells) || 4.8,
      hemoglobin: parseFloat(hemoglobin) || 14.75,
      hematocrit: parseFloat(hematocrit) || 42.45,
      mean_corpuscular_volume_mcv: parseFloat(mean_corpuscular_volume_mcv) || 89.75,
      mean_corpuscular_hemoglobin_mch: parseFloat(mean_corpuscular_hemoglobin_mch) || 30.0,
      mean_corpuscular_hemoglobin_concentration_mchc: parseFloat(mean_corpuscular_hemoglobin_concentration_mchc) || 33.5,
      red_blood_cell_distribution_width_rdw: parseFloat(red_blood_cell_distribution_width_rdw) || 13.5,
      leukocytes: parseFloat(leukocytes) || 7500.0,
      myeloblasts: parseFloat(myeloblasts) || 7500.0,
      promyelocytes: parseFloat(promyelocytes) || 7.5,
      myelocytes: parseFloat(myelocytes) || 7.5,
      metamyelocytes: parseFloat(metamyelocytes) || 7.5,
      segmented: parseFloat(segmented) || 55.0,
      eosinophils: parseFloat(eosinophils) || 4.0,
      basophils: parseFloat(basophils) || 0.5,
      lymphocytes: parseFloat(lymphocytes) || 30.0,
      monocytes: parseFloat(monocytes) || 55.0,
      neutrophils: parseFloat(neutrophils) || 4800.0,
      platelets: parseFloat(platelets) || 300000.0,
      mean_platelet_volume: parseFloat(mean_platelet_volume) || 300000.0,
      direct_bilirubin: parseFloat(direct_bilirubin) || 0.205,
      indirect_bilirubin: parseFloat(indirect_bilirubin) || 0.5,
      total_bilirubin: parseFloat(total_bilirubin) || 0.7,
      serum_glucose: parseFloat(serum_glucose) || 84.5,
      lipase_dosage: parseFloat(lipase_dosage) || 36.5,
      urea: parseFloat(urea) || 33.0,
      partial_thromboplastin_time_ptt: parseFloat(partial_thromboplastin_time_ptt) || 12.0,
      d_dimer: parseFloat(d_dimer) || 0.285,
      lactic_dehydrogenase: parseFloat(lactic_dehydrogenase) || 180.0,
      prothrombin_time_pt_activity: parseFloat(prothrombin_time_pt_activity) || 12.0,
      creatinine: parseFloat(creatinine) || 0.85,
      proteina_c_reativa_mgdl: parseFloat(proteina_c_reativa_mgdl) || 2.5,
      hb_saturation_arterial_blood_gases: parseFloat(hb_saturation_arterial_blood_gases) || 0.0,
      p_o2_arterial_blood_gas_analysis: parseFloat(p_o2_arterial_blood_gas_analysis) || 90.0,
      p_co2_arterial_blood_gas_analysis: parseFloat(p_co2_arterial_blood_gas_analysis) || 40.0,
      total_co2_arterial_blood_gas_analysis: parseFloat(total_co2_arterial_blood_gas_analysis) || 40.0,
      hco3_arterial_blood_gas_analysis: parseFloat(hco3_arterial_blood_gas_analysis) || 24.0,
      p_h_arterial_blood_gas_analysis: parseFloat(p_h_arterial_blood_gas_analysis) || 7.4,
      base_excess_arterial_blood_gas_analysis: parseFloat(base_excess_arterial_blood_gas_analysis) || 0.0,
      arteiral_fio2: parseFloat(arteiral_fio2) || 97.5,
      ct_o2_arterial_blood_gas_analysis: parseFloat(ct_o2_arterial_blood_gas_analysis) || 96.0,
    });

    yield put(ExamActions.examSuccess(response.data));
    Notification({title: 'Informação', description: 'Exame realizado. O preenchimento incompleto dos exames pode acarretar em baixa precisão do diagnóstico', duration: 5, type: 'info'})

  } catch (err) {
    const message = err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao tentar realizar o exame.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(ExamActions.examFailure(err));
  }
}

export function* firstStep(params) {
  try {
    yield put(ExamActions.firstStepSuccess(params.payload));
  } catch (err) {
    if (err.response) {
      const [errorMessage] = err.response.data.errors;
      message.error(errorMessage);
    }
  }
}

export function* secondStep(params) {
  try {
    yield put(ExamActions.secondStepSuccess(params.payload));
  } catch (err) {
    if (err.response) {
      const [errorMessage] = err.response.data.errors;
      message.error(errorMessage);
    }
  }
}

export function* thirdStep(params) {
  try {
    yield put(ExamActions.thirdStepSuccess(params.payload));
  } catch (err) {
    if (err.response) {
      const [errorMessage] = err.response.data.errors;
      message.error(errorMessage);
    }
  }
}

export function* fourthStep(params) {
  try {
    yield put(ExamActions.fourthStepSuccess(params.payload));
  } catch (err) {
    if (err.response) {
      const [errorMessage] = err.response.data.errors;
      message.error(errorMessage);
    }
  }
}

export function* uploadExam({ payload }) {
  try {
    const data = new FormData();

    data.append('file', payload.file);

    const response = yield call(api.post, `read-pdf-blood-test`, data);

    yield put(ExamActions.uploadExamSuccess(response.data));
    Notification({title: 'Sucesso', description: 'Arquivo anexado com sucesso', duration: 5, type: 'success'})
  } catch (err) {
    const message = err.response && err.response.data && err.response.data.msg !== ''
      ? err.response.data.msg 
      : 'Erro interno! Falha ao transformar o PDF.';
    
    if((err.response && err.response.data) && err.response.data.msg === 'Token has expired'){
      Notification({title: 'Erro', description: 'Seu token expirou, te redirecionamos para fazer um novo login.', duration: 5, type: 'error'})
      store.dispatch(push('/login'));
      return
    }
    Notification({title: 'Erro', description: message, duration: 3, type: 'error'})
    yield put(ExamActions.uploadExamFailure(err));
  }
}