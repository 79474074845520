import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import session from './session';
import consult from './consult';
import exam from './exam';
import report from './report';
import user from './user';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  session,
  consult,
  exam,
  report,
  user,
});

export default createRootReducer;
