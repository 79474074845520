import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Row,
} from 'antd';
import compose from 'compose-function';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import RegisterForm from './form';
import { Creators as UserActions } from '~/redux/ducks/user';
import PageHeader from '~/components/PageHeader';

function Register({
  t,
  history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/app/home'],
    [`${t('Exame')}`, '/app/exam'],
    [`${t('Cadastro')}`, '/app/register'],
    [`${t('Histórico')}`, '/app/historic'],
  ]);

  useEffect(() => {
    if(localStorage.getItem('usertype') !== 'admin'){
      history.push('/app/home')
    }
  }, [history])

  return (
    <div className="exam-container">
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div style={{ paddingLeft: 90, marginTop: 30 }}>
        <PageHeader title="Registro de Laudista" subtitle="" />
      </div>
      <div className="exam">
        <RegisterForm />
      </div>
      <Row className="footer-exam">
        <PageFooter />
      </Row>
    </div>
  );
}

Register.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

Register.defaultProps = {
  history: [],
};


const mapStateToProps = (state) => ({
  loading: state.exam.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(UserActions, dispatch);

export default compose(
  withTranslation(), connect(mapStateToProps, mapDispatchToProps),
)(Register);
