import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col
} from 'antd';
import { withTranslation } from 'react-i18next';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageHeader from '~/components/PageHeader';
import Image from '~/components/Image';
import suport from '~/assets/img/suport';

function Suport({
  t,
  history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/welcome'],
    [`${t('login')}`, '/login'],
    ['Parceiros', '/consult'],
    [`${t('about')}`, '/about'],
  ]);

  return (
    <>
      <TopNav
        className="navbar-logged"
        logo={Logo}
        menuItems={menuItems}
        history={history}
      />
      <div className="column-centered">
        <div style={{ marginLeft: 100, marginTop: 30 }}>
          <PageHeader title="Nossos parceiros" />
          <p>Pelo apoio financeiro para o desenvolvimento do sistema, agradecemos a:</p>
          <Row style={{marginBottom: 50, alignItems: 'center', display: 'flex'}}>
            <Col span={4}>
              <Image
                src={suport.ufpe}
                width="180"
              />
            </Col>
            <Col span={4}>
              <Image
                src={suport.google}
                width="180"
              />
            </Col>
            <Col span={4}>
              <Image
                src={suport.facepe}
                width="180"
              />
            </Col>
            <Col span={4}>
              <Image
                src={suport.cnpq}
                width="180"
              />
            </Col>
            <Col span={4}>
              <Image
                src={suport.capes}
                width="180"
              />
            </Col>
          </Row>
          <p>Pelos dados para o desenvolvimento, agradecemos a:</p>
          <Row style={{marginBottom: 50}}>
            <Col span={4}>
              <Image
                src={suport.paudalho}
                width="210"
              />
            </Col>
          </Row>
          <p>Pelas imagens utilizadas no sistema, agradecemos a:</p>
          <Row style={{marginBottom: 50}}>
            <Col span={4}>
              <Image
                src={suport.freepik}
                width="180"
              />
            </Col>
          </Row>
        </div>
        
      </div>
    </>
  );
}

Suport.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

Suport.defaultProps = {
  history: [],
};

export default withTranslation()(Suport);
