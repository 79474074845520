import enLocale from 'antd/es/locale/en_US';
import ptBRLocale from 'antd/es/locale/pt_BR';
import i18n from './i18n';

const locales = {
  en: enLocale,
  'pt-BR': ptBRLocale,
};
const AntdLocale = locales[i18n.options.lng];

export { AntdLocale };
