/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import {
  Col, Row,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Creators as ExamActions } from '~/redux/ducks/exam';

function ThirdStep({ thirdStepRequest, tempThirdStep }) {
  const [direct_bilirubin, setdirect_bilirubin] = useState(tempThirdStep.direct_bilirubin);
  const [indirect_bilirubin, setindirect_bilirubin] = useState(tempThirdStep.indirect_bilirubin);
  const [total_bilirubin, settotal_bilirubin] = useState(tempThirdStep.total_bilirubin);
  const [serum_glucose, setserum_glucose] = useState(tempThirdStep.serum_glucose);
  const [lipase_dosage, setlipase_dosage] = useState(tempThirdStep.lipase_dosage);
  const [urea, seturea] = useState(tempThirdStep.urea);
  const [partial_thromboplastin_time_ptt, setpartial_thromboplastin_time_ptt] = useState(tempThirdStep.partial_thromboplastin_time_ptt);
  const [d_dimer, setd_dimer] = useState(tempThirdStep.d_dimer);
  const [lactic_dehydrogenase, setlactic_dehydrogenase] = useState(tempThirdStep.lactic_dehydrogenase);
  const [prothrombin_time_pt_activity, setprothrombin_time_pt_activity] = useState(tempThirdStep.prothrombin_time_pt_activity);
  const [creatinine, setcreatinine] = useState(tempThirdStep.creatinine);
  const [proteina_c_reativa_mgdl, setproteina_c_reativa_mgdl] = useState(tempThirdStep.proteina_c_reativa_mgdl);

  useEffect(() => {
    thirdStepRequest({
      direct_bilirubin,
      indirect_bilirubin,
      total_bilirubin,
      serum_glucose,
      lipase_dosage,
      urea,
      partial_thromboplastin_time_ptt,
      d_dimer,
      lactic_dehydrogenase,
      prothrombin_time_pt_activity,
      creatinine,
      proteina_c_reativa_mgdl
    });
  }, [
    direct_bilirubin,
    indirect_bilirubin,
    total_bilirubin,
    serum_glucose,
    lipase_dosage,
    urea,
    partial_thromboplastin_time_ptt,
    d_dimer,
    lactic_dehydrogenase,
    prothrombin_time_pt_activity,
    creatinine,
    proteina_c_reativa_mgdl,
    thirdStepRequest]);

  return (
    <div className="step-form">
      <Row>
        <Col span={16}>
          <Row>
            <span className="exam-title">BILIRRUBINA</span>
          </Row>
          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">DIRETA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={direct_bilirubin}
                onChange={(e) => setdirect_bilirubin(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">INDIRETA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={indirect_bilirubin}
                onChange={(e) => setindirect_bilirubin(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">TOTAL:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={total_bilirubin}
                onChange={(e) => settotal_bilirubin(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>

          <Row>
            <span className="exam-title">DEMAIS EXAMES</span>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">GLICOSE SÉRICA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={serum_glucose}
                onChange={(e) => setserum_glucose(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">LIPASE: </span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={lipase_dosage}
                onChange={(e) => setlipase_dosage(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">U/L</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">URÉIA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={urea}
                onChange={(e) => seturea(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">TEMPO PARCIAL DE TROMBOPLASTINA PTT:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={partial_thromboplastin_time_ptt}
                onChange={(e) => setpartial_thromboplastin_time_ptt(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">s</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">DÍMERO D:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={d_dimer}
                onChange={(e) => setd_dimer(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">ug/mL</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">LACTATO DESIDROGENASE SÉRICA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={lactic_dehydrogenase}
                onChange={(e) => setlactic_dehydrogenase(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">U/L</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">TAP (coagulograma):</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={prothrombin_time_pt_activity}
                onChange={(e) => setprothrombin_time_pt_activity(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">s</span>
            </Col>
          </Row>

          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">PROTEÍNA C-REATIVA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={proteina_c_reativa_mgdl}
                onChange={(e) => setproteina_c_reativa_mgdl(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>
          <Row className="input-line">
            <Col span={9} className="exame-text-container">
              <span className="exam-text">CREATININA:</span>
            </Col>
            <Col span={6} style={{ marginRight: 10 }}>
              <input
                type='number'
                className="ant-input"
                value={creatinine}
                onChange={(e) => setcreatinine(e.target.value)}
              />
            </Col>
            <Col span={7} className="exame-text-container">
              <span className="exam-text">mg/dL</span>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <span className="exam-title">VALORES DE REFERÊNCIA</span>
          </Row>
          <Row className="marginT-30">
            <span className="ref-text">0,1 - 0,4</span>
          </Row>

          <Row style={{marginTop: 40}}>
            <span className="ref-text">0,2 - 0,8</span>
          </Row>

          <Row style={{marginTop: 40}}>
            <span className="ref-text">0,2 - 1,2</span>
          </Row>

          {/* Demais exames */}
          <Row style={{marginTop: 90}}>
            <span className="ref-text">70,0 - 99,0</span>
          </Row>

          <Row style={{marginTop: 38}}>
            <span className="ref-text">13,0 - 60,0</span>
          </Row>

          <Row style={{marginTop: 38}}>
            <span className="ref-text">16,0 - 50,0</span>
          </Row>

          <Row style={{marginTop: 38}}>
            <span className="ref-text">10 - 14</span>
          </Row>

          <Row style={{marginTop: 61}}>
            <span className="ref-text">0,07 - 0,500</span>
          </Row>

          <Row style={{marginTop: 40}}>
            <span className="ref-text">135 - 225</span>
          </Row>

          <Row style={{marginTop: 60}}>
            <span className="ref-text">10 - 14</span>
          </Row>
          <Row className="marginT-43">
            <span className="ref-text">0 - 5</span>
          </Row>

          <Row style={{marginTop: 38}}>
            <span className="ref-text">0,5 - 1,2</span>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

ThirdStep.propTypes = {
  thirdStepRequest: PropTypes.func.isRequired,
  tempThirdStep: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempThirdStep: state.exam.tempThirdStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
