import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Raquel() {
  const body = (
    <div className="people">
      <p className="people-name">Raquel Calado, MSc</p>
      <p className="people-description">Graduada em Sistemas de Informação (UPE)</p>
      <p className="people-description">Mestre em Engenharia Biomédica (UFPE)</p>
      <p className="people-description">Mestre em Engenharia da Computação (POLI-UPE)</p>
    </div>
  );
  return (
    <AboutCard src={people.raquel} body={body} size="sm" />
  );
}
