/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import {
  Row, Col
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Creators as ExamActions } from '~/redux/ducks/exam';

function SecondStep({ secondStepRequest, tempSecondStep, uploadData }) {
  const [red_blood_cells, setRed_blood_cells] = useState(tempSecondStep.red_blood_cells);
  const [hemoglobin, sethemoglobin] = useState(tempSecondStep.hemoglobin);
  const [hematocrit, sethematocrit] = useState(tempSecondStep.hematocrit);
  const [mean_corpuscular_volume_mcv, setmean_corpuscular_volume_mcv] = useState(tempSecondStep.mean_corpuscular_volume_mcv);
  const [mean_corpuscular_hemoglobin_mch, setmean_corpuscular_hemoglobin_mch] = useState(tempSecondStep.mean_corpuscular_hemoglobin_mch);
  const [mean_corpuscular_hemoglobin_concentration_mchc, setmean_corpuscular_hemoglobin_concentration_mchc] = useState(tempSecondStep.mean_corpuscular_hemoglobin_concentration_mchc);
  const [red_blood_cell_distribution_width_rdw, setred_blood_cell_distribution_width_rdw] = useState(tempSecondStep.red_blood_cell_distribution_width_rdw);
  const [leukocytes, setleukocytes] = useState(tempSecondStep.leukocytes);
  const [myeloblasts, setmyeloblasts] = useState(tempSecondStep.myeloblasts);
  const [promyelocytes, setpromyelocytes] = useState(tempSecondStep.promyelocytes);
  const [myelocytes, setmyelocytes] = useState(tempSecondStep.myelocytes);
  const [metamyelocytes, setmetamyelocytes] = useState(tempSecondStep.metamyelocytes);
  const [segmented, setsegmented] = useState(tempSecondStep.segmented);
  const [eosinophils, seteosinophils] = useState(tempSecondStep.eosinophils);
  const [basophils, setbasophils] = useState(tempSecondStep.basophils);
  const [lymphocytes, setlymphocytes] = useState(tempSecondStep.lymphocytes);
  const [monocytes, setmonocytes] = useState(tempSecondStep.monocytes);
  const [neutrophils, setneutrophils] = useState(tempSecondStep.neutrophils);
  const [platelets, setplatelets] = useState(tempSecondStep.platelets);
  const [mean_platelet_volume, setmean_platelet_volume] = useState(tempSecondStep.mean_platelet_volume);

  useEffect(() => {
      secondStepRequest({
        red_blood_cells,
        hemoglobin,
        hematocrit,
        mean_corpuscular_volume_mcv,
        mean_corpuscular_hemoglobin_mch,
        mean_corpuscular_hemoglobin_concentration_mchc,
        red_blood_cell_distribution_width_rdw,
        leukocytes,
        myeloblasts,
        promyelocytes,
        myelocytes,
        metamyelocytes,
        segmented,
        eosinophils,
        basophils,
        lymphocytes,
        monocytes,
        neutrophils,
        platelets,
        mean_platelet_volume,
      });
  }, [
    red_blood_cells,
    hemoglobin,
    hematocrit,
    mean_corpuscular_volume_mcv,
    mean_corpuscular_hemoglobin_mch,
    mean_corpuscular_hemoglobin_concentration_mchc,
    red_blood_cell_distribution_width_rdw,
    leukocytes,
    myeloblasts,
    promyelocytes,
    myelocytes,
    metamyelocytes,
    segmented,
    eosinophils,
    basophils,
    lymphocytes,
    monocytes,
    neutrophils,
    platelets,
    mean_platelet_volume,
    secondStepRequest]);

  return (
    <div>
      <div className="step-form">
        <Row>
          <Col span={16}>
            <Row>
              <span className="exam-title">SÉRIE VERMELHA</span>
            </Row>
            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">GLÓBULOS VERMELHOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={red_blood_cells}
                  onChange={(e) => setRed_blood_cells(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">milhões/mm³</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">HEMOGLOBINA:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={hemoglobin}
                  onChange={(e) => sethemoglobin(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">g/dL</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">HEMATÓCRITO:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={hematocrit}
                  onChange={(e) => sethematocrit(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">VCM:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={mean_corpuscular_volume_mcv}
                  onChange={(e) => setmean_corpuscular_volume_mcv(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">fL</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">HCM:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={mean_corpuscular_hemoglobin_mch}
                  onChange={(e) => setmean_corpuscular_hemoglobin_mch(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">pg</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">CHCM:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={mean_corpuscular_hemoglobin_concentration_mchc}
                  onChange={(e) => setmean_corpuscular_hemoglobin_concentration_mchc(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">uL</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">RDW:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={red_blood_cell_distribution_width_rdw}
                  onChange={(e) => setred_blood_cell_distribution_width_rdw(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row>
              <span className="exam-title">SÉRIE BRANCA</span>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">LEUCÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={leukocytes}
                  onChange={(e) => setleukocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">/mm³</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">MIELOBLASTOS: </span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={myeloblasts}
                  onChange={(e) => setmyeloblasts(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">/µL</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">PROMIELÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={promyelocytes}
                  onChange={(e) => setpromyelocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">MIELÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={myelocytes}
                  onChange={(e) => setmyelocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">METAMIELÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={metamyelocytes}
                  onChange={(e) => setmetamyelocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">SEGMENTADOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={segmented}
                  onChange={(e) => setsegmented(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">EOSINÓFILOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={eosinophils}
                  onChange={(e) => seteosinophils(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">BASÓFILOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={basophils}
                  onChange={(e) => setbasophils(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">LINFÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={lymphocytes}
                  onChange={(e) => setlymphocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">MONÓCITOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={monocytes}
                  onChange={(e) => setmonocytes(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">%</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">NEUTRÓFILOS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={neutrophils}
                  onChange={(e) => setneutrophils(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">mm³</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-title">PLAQUETAS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={platelets}
                  onChange={(e) => setplatelets(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">/µL</span>
              </Col>
            </Row>

            <Row className="input-line">
              <Col span={9} className="exame-text-container">
                <span className="exam-text">VOL. MÉDIO DE PLAQUETAS:</span>
              </Col>
              <Col span={6} style={{ marginRight: 10 }}>
                <input
                  type='number'
                  className="ant-input"
                  value={mean_platelet_volume}
                  onChange={(e) => setmean_platelet_volume(e.target.value)}
                />
              </Col>
              <Col span={7} className="exame-text-container">
                <span className="exam-text">/µL</span>
              </Col>
            </Row>

          </Col>
          <Col span={8}>
            <Row>
              <span className="exam-title">VALORES DE REFERÊNCIA</span>
            </Row>
            <Row className="marginT-30">
              <span className="ref-text">3,9 - 5,7</span>
            </Row>

            <Row style={{marginTop: 40}}>
              <span className="ref-text">12,0 - 17,5</span>
            </Row>

            <Row style={{marginTop: 35}}>
              <span className="ref-text">34,9 - 50</span>
            </Row>

            <Row style={{marginTop: 40}}>
              <span className="ref-text">81,2 - 98,3</span>
            </Row>

            <Row style={{marginTop: 40}}>
              <span className="ref-text">26,0 - 34,0</span>
            </Row>

            <Row style={{marginTop: 40}}>
              <span className="ref-text">31 - 36</span>
            </Row>

            <Row style={{marginTop: 36}}>
              <span className="ref-text">11,6 - 14,9</span>
            </Row>

            {/* Serie Branca */}
            <Row style={{marginTop: 90}}>
              <span className="ref-text">4.000,0 - 11.000,0</span>
            </Row>

            <Row style={{marginTop: 40}}>
              <span className="ref-text">4.000,0 - 11.000,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">0,0 - 0,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">0,0 - 0,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">0,0 - 0,0</span>
            </Row>

            <Row className="marginT-43">
              <span className="ref-text">40,0 - 70,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">1,0 - 7,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">0,0 - 1,0</span>
            </Row>

            <Row style={{marginTop: 37}}>
              <span className="ref-text">20,0 - 40,0</span>
            </Row>

            <Row style={{marginTop: 39}}>
              <span className="ref-text">2,0 - 9,0</span>
            </Row>

            <Row style={{marginTop: 39}}>
              <span className="ref-text">1.600,0 - 8.000,0</span>
            </Row>

            <Row style={{marginTop: 39, width: 300}}>
              <span className="ref-text">150.000,0 - 450.000,0</span>
            </Row>
            <Row style={{marginTop: 39, width: 300}}>
              <span className="ref-text">150.000,0 - 450.000,0</span>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

SecondStep.propTypes = {
  uploadExamRequest: PropTypes.func.isRequired,
  secondStepRequest: PropTypes.func.isRequired,
  tempSecondStep: PropTypes.object.isRequired,
  uploadData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempSecondStep: state.exam.tempSecondStep,
  uploadData: state.exam.uploadData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
