import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Claudio() {
  const body = (
    <div className="people">
      <p className="people-name">Cláudio Bertoldo Jr</p>
      <p className="people-description">Engenheiro  Elétrico e de Telecomunicações (INATEL)</p>
      <p className="people-description">Possui título de MBA (FGV)</p>
      <p className="people-description">Mestre em Engenharia da Computação e Elétrica (Unicamp)</p>
    </div>
  );
  return (
    <AboutCard src={people.claudio} body={body} size="sm" />
  );
}
