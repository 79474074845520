export const Types = {
  CAD_PATIENT_REQUEST: 'CAD_PATIENT_REQUEST',
  CAD_PATIENT_SUCCESS: 'CAD_PATIENT_SUCCESS',
  CAD_PATIENT_FAILURE: 'CAD_PATIENT_FAILURE',

  EXAM_REQUEST: 'EXAM_REQUEST',
  EXAM_SUCCESS: 'EXAM_SUCCESS',
  EXAM_FAILURE: 'EXAM_FAILURE',

  RESULT_REQUEST: 'RESULT_REQUEST',
  RESULT_SUCCESS: 'RESULT_SUCCESS',
  RESULT_FAILURE: 'RESULT_FAILURE',

  TEMP_FIRST_STEP_EXAM_REQUEST: 'TEMP_FIRST_STEP_EXAM_REQUEST',
  TEMP_FIRST_STEP_EXAM_SUCCESS: 'TEMP_FIRST_STEP_EXAM_SUCCESS',

  TEMP_SECOND_STEP_EXAM_REQUEST: 'TEMP_SECOND_STEP_EXAM_REQUEST',
  TEMP_SECOND_STEP_EXAM_SUCCESS: 'TEMP_SECOND_STEP_EXAM_SUCCESS',

  TEMP_THIRD_STEP_EXAM_REQUEST: 'TEMP_THIRD_STEP_EXAM_REQUEST',
  TEMP_THIRD_STEP_EXAM_SUCCESS: 'TEMP_THIRD_STEP_EXAM_SUCCESS',

  TEMP_FOURTH_STEP_EXAM_REQUEST: 'TEMP_FOURTH_STEP_EXAM_REQUEST',
  TEMP_FOURTH_STEP_EXAM_SUCCESS: 'TEMP_FOURTH_STEP_EXAM_SUCCESS',

  UPLOAD_EXAM_REQUEST: 'UPLOAD_EXAM_REQUEST',
  UPLOAD_EXAM_SUCCESS: 'UPLOAD_EXAM_SUCCESS',
  UPLOAD_EXAM_FAILURE: 'UPLOAD_EXAM_FAILURE',
};

const INITIAL_STATE = {
  loading: false,
  examResult: {},
  examUser: {},
  patient: {},
  tempFirstStep: {},
  tempSecondStep: {},
  tempThirdStep: {},
  tempFourthStep: {},
  uploadData: {},
};

export default function Exam(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CAD_PATIENT_REQUEST:
      return { ...state, loading: true };
    case Types.CAD_PATIENT_SUCCESS:
      return { ...state, loading: false };
    case Types.CAD_PATIENT_FAILURE:
      return {
        ...state, patient: action.payload, loading: false,
      };

    case Types.EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.EXAM_SUCCESS:
      return { ...state, 
        examResult: action.payload, 
        tempFirstStep:{},
        tempSecondStep: {},
        tempThirdStep: {},
        tempFourthStep: {},
        uploadData: {},
        patient: {},
        loading: false 
      };
    case Types.EXAM_FAILURE:
      return { ...state, loading: false };

    case Types.RESULT_REQUEST:
      return { ...state, loading: true };
    case Types.RESULT_SUCCESS:
      return { ...state, examUser: action.payload, loading: false };
    case Types.RESULT_FAILURE:
      return { ...state, loading: false };

    case Types.TEMP_FIRST_STEP_EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.TEMP_FIRST_STEP_EXAM_SUCCESS:
      return { ...state, tempFirstStep: action.payload, loading: false };

    case Types.TEMP_SECOND_STEP_EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.TEMP_SECOND_STEP_EXAM_SUCCESS:
      return { ...state, tempSecondStep: action.payload, loading: false };

    case Types.TEMP_THIRD_STEP_EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.TEMP_THIRD_STEP_EXAM_SUCCESS:
      return { ...state, tempThirdStep: action.payload, loading: false };

    case Types.TEMP_FOURTH_STEP_EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.TEMP_FOURTH_STEP_EXAM_SUCCESS:
      return { ...state, tempFourthStep: action.payload, loading: false };

    case Types.UPLOAD_EXAM_REQUEST:
      return { ...state, loading: true };
    case Types.UPLOAD_EXAM_SUCCESS:
      return { ...state, uploadData: action.payload, loading: false };
    case Types.UPLOAD_EXAM_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  cadPatientRequest: (params) => ({
    type: Types.CAD_PATIENT_REQUEST,
    payload: params,
  }),


  cadPatientSuccess: (response) => ({
    type: Types.CAD_PATIENT_SUCCESS,
    payload: response,
  }),

  cadPatientFailure: (reason) => ({
    type: Types.CAD_PATIENT_FAILURE,
    payload: reason,
  }),

  examRequest: (payload) => ({
    type: Types.EXAM_REQUEST,
    payload,
  }),


  examSuccess: (response) => ({
    type: Types.EXAM_SUCCESS,
    payload: response,
  }),

  examFailure: (reason) => ({
    type: Types.EXAM_FAILURE,
    payload: reason,
  }),

  resultRequest: (id) => ({
    type: Types.RESULT_REQUEST,
    id,
  }),


  resultSuccess: (response) => ({
    type: Types.RESULT_SUCCESS,
    payload: response,
  }),

  resultFailure: (reason) => ({
    type: Types.RESULT_FAILURE,
    payload: reason,
  }),

  firstStepRequest: (params) => ({
    type: Types.TEMP_FIRST_STEP_EXAM_REQUEST,
    payload: params,
  }),


  firstStepSuccess: (response) => ({
    type: Types.TEMP_FIRST_STEP_EXAM_SUCCESS,
    payload: response,
  }),

  secondStepRequest: (params) => ({
    type: Types.TEMP_SECOND_STEP_EXAM_REQUEST,
    payload: params,
  }),


  secondStepSuccess: (response) => ({
    type: Types.TEMP_SECOND_STEP_EXAM_SUCCESS,
    payload: response,
  }),

  thirdStepRequest: (params) => ({
    type: Types.TEMP_THIRD_STEP_EXAM_REQUEST,
    payload: params,
  }),


  thirdStepSuccess: (response) => ({
    type: Types.TEMP_THIRD_STEP_EXAM_SUCCESS,
    payload: response,
  }),

  fourthStepRequest: (params) => ({
    type: Types.TEMP_FOURTH_STEP_EXAM_REQUEST,
    payload: params,
  }),


  fourthStepSuccess: (response) => ({
    type: Types.TEMP_FOURTH_STEP_EXAM_SUCCESS,
    payload: response,
  }),

  uploadExamSuccess: (data) => ({
    type: Types.UPLOAD_EXAM_SUCCESS,
    payload: data,
  }),

  uploadExamFailure: (data) => ({
    type: Types.UPLOAD_EXAM_FAILURE,
    payload: data,
  }),

  uploadExamRequest: (file) => ({
    type: Types.UPLOAD_EXAM_REQUEST,
    payload: { file },
  }),

};
