import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import i18n from './config/i18n';
import './config/moment';
import 'antd/dist/antd.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
