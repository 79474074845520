import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Ricardo() {
  const body = (
    <div className="people">
      <p className="people-name">Prof. Dr. Ricardo Emmanuel de Souza</p>
      <p className="people-role">Pesquisador associado </p>
      <p className="people-description">Docente do Departamento de Engenharia Biomédica da UFPE.</p>
      <p className="people-description">Engenhario eletrônico pela UFPE</p>
      <p className="people-description"> Mestre em Física pela UFPE </p>
      <p className="people-description"> Doutor em Física pela UFPE </p>
    </div>
  );
  return (
    <AboutCard src={people.ricardo} body={body} size='middlelg'/>
  );
}
