import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { Creators as ExamActions } from '~/redux/ducks/exam';

const AdditionalExams = ({ tempThirdStep }) => (
  <div className="session">
    <span className="visualize-session-title">Dados dos Exames Sorológicos Adicionais</span>
    <Row className="marginL-5">
      <Col span={8}>
        <span className="visualize-title">Bilirrubina Direta</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.direct_bilirubin ? `${tempThirdStep.direct_bilirubin} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Glicose Sérica</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.serum_glucose ? `${tempThirdStep.serum_glucose} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Dímero D</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.d_dimer ? `${tempThirdStep.d_dimer} ug/mL` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Bilirrubina Indireta</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.indirect_bilirubin ? `${tempThirdStep.indirect_bilirubin} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Lipase</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.indirect_bilirubin ? `${tempThirdStep.lipase_dosage} U/L` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Lactato Desidrogenase Sérica</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.lactic_dehydrogenase ? `${tempThirdStep.lactic_dehydrogenase} U/L` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Bilirrubina Total</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.total_bilirubin ? `${tempThirdStep.total_bilirubin} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Uréia</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.urea ? `${tempThirdStep.urea} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">TAP (coagulograma)</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.prothrombin_time_pt_activity ? `${tempThirdStep.prothrombin_time_pt_activity} s` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Tempo parcial de Tromboplastina PTT</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.partial_thromboplastin_time_ptt ? `${tempThirdStep.partial_thromboplastin_time_ptt} s` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Proteína C-REATIVA</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.proteina_c_reativa_mgdl ? `${tempThirdStep.proteina_c_reativa_mgdl} mg/dL` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Creatinina</span>
        <br />
        <span className="visualize-content">
          {tempThirdStep.creatinine ? `${tempThirdStep.creatinine} mg/dL` : ' '}
        </span>
      </Col>
    </Row>
  </div>
);

AdditionalExams.propTypes = {
  tempThirdStep: PropTypes.object,
};

AdditionalExams.defaultProps = {
  tempThirdStep: {},
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempThirdStep: state.exam.tempThirdStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalExams);
