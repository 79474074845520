/* eslint-disable global-require */
const people = {
  wellington: require('./avatar/wellington.png'),
  ricardo: require('./avatar/ricardo.png'),
  juliana: require('./avatar/juliana.png'),
  valter: require('./avatar/valter.png'),
  maira: require('./avatar/maira.png'),
  raquel: require('./avatar/raquel.png'),
  rodrigo: require('./avatar/rodrigo.png'),
  karina: require('./avatar/karina.png'),
  jenifer: require('./avatar/jenifer.png'),
  david: require('./avatar/david.png'),
  reiga: require('./avatar/reiga.png'),
  claudio: require('./avatar/claudio.png'),
  anderson: require('./avatar/anderson.png'),
  thifany: require('./avatar/thifany.png'),
  maria: require('./avatar/maria.png'),
  gabriel: require('./avatar/gabriel.png'),
  jose: require('./avatar/jose.png'),
  vitoria: require('./avatar/vitoria.png'),
  luiz: require('./avatar/luiz.png'),
  clarice: require('./avatar/clarice.png'),
};

export default people;
