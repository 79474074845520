import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Juliana() {
  const body = (
    <div className="people">
      <p className="people-name"> Juliana Carneiro Gomes, MSc</p>
      <p className="people-description">Engenheira Biomédica (UFPE)</p>
      <p className="people-description">Mestre em Engenharia Biomédica (UFPE)</p>
      <p className="people-description"> Doutoranda em Engenharia  da Computação (POLI- UPE) </p>
    </div>
  );
  return (
    <AboutCard src={people.juliana} body={body} size='mlg'/>
  );
}
