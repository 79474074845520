import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button, Row, Col, Form, Input,
} from 'antd';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageHeader from '~/components/PageHeader';
import PageFooter from '~/components/PageFooter';

function ForgotPasswordPage({
  t, history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/welcome'],
    [`${t('login')}`, '/login'],
    ['Senha', '/forgotpassword'],
    ['Parceiros', '/consult'],
    [`${t('about')}`, '/about'],
  ]);

  return (
    <>
      <TopNav
        className="navbar-logged"
        logo={Logo}
        menuItems={menuItems}
        history={history}
      />
      <div className="login">
        <PageHeader title="Recuperação de senha" subtitle="Esqueceu a senha? Digite o email utilizado para criar sua conta no campo abaixo, te enviaremos uma nova senha por e-mail." />
        <Row>
          <Col span={12}>
            <Form className="login-form">
              <Form.Item rules={[{ required: true, message: 'Please input your Username!' }]}>
            Email
                <Input name="email" id="email" placeholder="usuario@email.com" />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="btn-default btn-login"
                >
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
      <PageFooter />
    </>
  );
}

ForgotPasswordPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  addresses: PropTypes.shape({}).isRequired,
};

ForgotPasswordPage.defaultProps = {
  history: [],
};

export default withTranslation()(ForgotPasswordPage);
