import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import './config/reactotron';
import { ConfigProvider } from 'antd';
import { AntdLocale } from './config/antd';

import history from './routes/history';
import App from './containers/App';
import store from './redux/store';

function Root() {
  return (
    <div className="App">
      <Suspense fallback={(<div />)}>
        <Provider store={store}>
          <ConfigProvider locale={AntdLocale}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/" component={App} />
              </Switch>
            </ConnectedRouter>
          </ConfigProvider>
        </Provider>
      </Suspense>
    </div>

  );
}

export default (Root);
