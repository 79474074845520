import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Wellington() {
  const body = (
    <div className="people">
      <p className="people-name">Prof. Dr. Wellington Pinheiro dos Santos</p>
      <p className="people-role">Coordenador</p>
      <p className="people-description">Docente do Departamento de Engenharia Biomédica da UFPE.</p>
      <p className="people-description"> Engenheiro Eletrônico</p>
      <p className="people-description"> Mestre em Engenharia Elétrica pela UFPE </p>
      <p className="people-description"> Doutor em Engenharia Elétrica pela UFCG </p>
    </div>
  );
  return (
    <AboutCard src={people.wellington} body={body} size='middlelg'/>
  );
}
