import React from 'react';
import PropTypes from 'prop-types';

function Image({
  alt,
  key,
  src,
  width,
  height,
  className,
}) {
  return (
    <img
      key={key}
      alt={alt}
      src={src}
      height={height}
      width={width}
      // onError={(e) => {
      //   e.currentTarget.onError = null;
      //   e.currentTarget.src = userImg;
      // }}
      className={className}
    />
  );
}

Image.propTypes = {
  key: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

Image.defaultProps = {
  key: null,
  alt: '',
  src: null,
  height: '',
  width: '',
  className: '',
};

export default Image;
