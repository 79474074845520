import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button, Row,
} from 'antd';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import PageHeader from '~/components/PageHeader';

function Home({
  t, history,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/app/home'],
    [`${t('Exame')}`, '/app/exam'],
    [`${t('Histórico')}`, '/app/historic'],
  ]);

  const handleButtons = () => (
    <Row className="btn_wrapper">
      <Button className="btn-default" onClick={() => history.push('/app/exam')}>Cadastrar exame</Button>
      {localStorage.getItem('usertype') === 'admin' ? (
        <Button className="btn-white btn-spacing" onClick={() => history.push('/app/register')}>Cadastrar Usuário</Button>

      ) : (
        <Button className="btn-white btn-spacing" onClick={() => history.push('/app/historic')}>Visualizar histórico</Button>
      )}
    </Row>
  );

  return (
    <div>
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div className="home">
        <PageHeader title={`Olá, ${localStorage.getItem('username')}!`} subtitle="O que você deseja fazer hoje?" />
        {handleButtons()}
      </div>
      <PageFooter />
    </div>
  );
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

Home.defaultProps = {
  history: [],
};

export default withTranslation()(Home);
