import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainRoute from '../routes';
import { Creators as SessionActions } from '~/redux/ducks/session';

import Welcome from '../pages/Welcome';
import Login from '../pages/Login';
import About from '../pages/About';
import Consult from '../pages/Consult';
import ForgotPassword from '../pages/ForgotPassword';

import '~/assets/sass/main.scss';

const InitialPath = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => ((user)
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: '/welcome',
            state: { from: props.location },
          }}
        />
      ))}
  />
);

InitialPath.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]).isRequired,
  user: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
};

InitialPath.defaultProps = {
  user: null,
};

const App = ({
  location, match, currentUser, userRequest,
}) => {
  useEffect(() => {
    if (currentUser && currentUser.accessToken) {
      userRequest({ userId: currentUser.uid || currentUser });
    }
  }, [userRequest, currentUser]);

  if (location.pathname === '/' || location.pathname === '/app' || location.pathname === '/app/') {
    return (<Redirect to='/app/home' />);
  }

  return (
    <>
      <Switch>
        <InitialPath
          path={`${match.url}app`}
          user={currentUser}
          component={MainRoute}
        />
        <Route path="/welcome" component={Welcome} />
        <Route path="/login" component={Login} />
        <Route path="/about" component={About} />
        <Route path="/consult" component={Consult} />
        <Route path="/forgotpassword" component={ForgotPassword} />
      </Switch>
    </>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  userRequest: PropTypes.func.isRequired,
};

App.defaultProps = {
  currentUser: null,
};

const mapStateToProps = (state) => ({
  currentUser: state.session.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(SessionActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
