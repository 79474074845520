import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Creators as ExamActions } from '~/redux/ducks/exam';

const RegularHosp = ({ examResult }) => (
  <div className="session">
    <span className="visualize-session-title">Atendimento Regular</span>
    <Row className="marginL-5">
      <Col span={8}>
        <span className="visualize-title">Indicado</span>
        <br />
        <span className="visualize-content">{(examResult && examResult.result) && examResult.result.regular_ward === true ? 'Sim' : 'Não'}</span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={6}>
        <span className="visualize-title">Acurácia</span>
        <br />
        <span className="visualize-content">{`${(examResult && examResult.metrics_regular_ward) && examResult.metrics_regular_ward.accuracy}%`}</span>
      </Col>
      <Col span={6} className="">
        <span className="visualize-title">Kappa</span>
        <br />
        <span className="visualize-content">{`${(examResult && examResult.metrics_regular_ward) && examResult.metrics_regular_ward.kappa}`}</span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Especificidade</span>
        <br />
        <span className="visualize-content">{`${(examResult && examResult.metrics_regular_ward) && examResult.metrics_regular_ward.specificity}%`}</span>
      </Col>
      <Col span={6}>
        <span className="visualize-title">Sensibilidade</span>
        <br />
        <span className="visualize-content">{`${(examResult && examResult.metrics_regular_ward) && examResult.metrics_regular_ward.sensitivity}%`}</span>
      </Col>
    </Row>
  </div>
);

RegularHosp.propTypes = {
  examResult: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  examResult: state.exam.examResult,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegularHosp);
