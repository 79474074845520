import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import UFPE from '~/assets/img/UFPE.png';
import DEBM from '~/assets/img/DEBM.png';
import Image from '~/components/Image';

const PageFooter = ({ className, type }) => (
  <div className={className}>
    <Row className={`page-footer${type ? '-' : ''}${type ? type : ''}`}>
      <Image src={UFPE} width="200" height="100" className="image" />
      <Image src={DEBM} width="150" height="60" className="image debm" />
    </Row>
  </div>
);

PageFooter.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

PageFooter.defaultProps = {
  className: '',
  type: ''
};

export default PageFooter;
