import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import compose from 'compose-function';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Row, Col, Form, Input,
} from 'antd';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import LoginImage from '~/assets/img/login_image.png';
import { Creators as SessionActions } from '~/redux/ducks/session';
import PageHeader from '~/components/PageHeader';
import PageFooter from '~/components/PageFooter';
import Image from '~/components/Image';
import Notification from '~/components/Notification'

function LoginPage({
  t,
  history,
  loginRequest,
  loading,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/welcome'],
    [`${t('login')}`, '/login'],
    ['Parceiros', '/consult'],
    [`${t('about')}`, '/about'],
  ]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onUserLogin = () => {
    if (email && password) {
      loginRequest({ email, password });
    }else{
      Notification({title: 'Erro', description: 'Por favor, preencha todos os campos para fazer o login.', duration: 3, type: 'error'})

    }
  };

  return (
    <>
      <TopNav
        className="navbar-logged"
        logo={Logo}
        menuItems={menuItems}
        history={history}
      />
      <div className="login">
        <PageHeader isLogin />
        <Row>
          <Col span={12}>
            <Form className="login-form">
              <Form.Item rules={[{ required: true, message: 'Please input your Username!' }]}>
            Email
                <Input name="email" id="email" type="email" placeholder="usuario@email.com" defaultValue={email} onChange={(inputVal) => setEmail(inputVal.target.value)} />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Please input your Password!' }]}>
            Senha
                <Input id="password" type="password" placeholder="********" defaultValue={email} onChange={(inputVal) => setPassword(inputVal.target.value)} />
              </Form.Item>
              <Row justify="space-between" className="login-form-links">
                <Col span={12}>
                  <a className="login-form-link" href="/forgotpassword">
                    {t('forgot_password')}
                  </a>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="submit"
                  className="btn-default btn-login"
                  onClick={() => (onUserLogin())}
                  disabled={loading}
                >
                  {t('sign_in')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={12} className="login-image">
            <Image
              src={LoginImage}
              width="500"
              height="300"
            />
          </Col>
        </Row>
        <PageFooter />
      </div>
    </>
  );
}

LoginPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  loading: PropTypes.bool.isRequired,
  loginRequest: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
  history: [],
};

const mapStateToProps = (state) => ({
  loading: state.session.loading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(SessionActions, dispatch);

export default compose(
  withTranslation(), connect(mapStateToProps, mapDispatchToProps),
)(LoginPage);
