export const Types = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILURE: 'USER_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
};

const obj = JSON.parse(localStorage.getItem('@SESSION')) || {};

const INITIAL_STATE = {
  loading: false,
  loadingPasswordRequest: false,
  accessToken: obj.accessToken,
  uid: obj.uid,
  tokenType: obj.tokenType,
  user: obj.user || {},
};

export default function Session(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true };
    case Types.LOGIN_FAILURE:
      return { ...state, loading: false };
    case Types.LOGIN_SUCCESS:
      return {
        ...state, ...action.payload.session, loading: false,
      };
    case Types.USER_REQUEST:
      return { ...state, loading: true };
    case Types.USER_FAILURE:
      return { ...state, loading: false };
    case Types.USER_SUCCESS:
      return {
        ...state, user: action.payload.user, loading: false,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
        accessToken: undefined,
        uid: undefined,
        tokenType: undefined,
      };
    default:
      return state;
  }
}

export const Creators = {
  loginRequest: (body) => ({
    type: Types.LOGIN_REQUEST,
    payload: {
      email: body.email,
      password: body.password,
    },
  }),


  loginSuccess: (user, session) => ({
    type: Types.LOGIN_SUCCESS,
    payload: {
      session,
      user,
    },
  }),

  loginFailure: (reason) => ({
    type: Types.LOGIN_FAILURE,
    payload: reason,
  }),

  userRequest: (payload) => ({
    type: Types.USER_REQUEST,
    payload,
  }),

  userSuccess: (payload) => ({
    type: Types.USER_SUCCESS,
    payload,
  }),

  userFailure: (reason) => ({
    type: Types.USER_FAILURE,
    payload: reason,
  }),

  logoutRequest: () => ({
    type: Types.LOGOUT_REQUEST,
  }),

  logoutSuccess: () => ({
    type: Types.LOGOUT_SUCCESS,
  }),

  clearUserSessions: (id) => ({
    type: Types.CLEAR_USER_SESSIONS,
    payload: id,
  }),
};
