import { Col } from 'antd';
import React from 'react';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as SessionActions } from '~/redux/ducks/session';

function StandardNav({
  user,
  menuItems,
  renderItem,
  logoutRequest,
  logged,
}) {
  return (
    <Col className="d-none-md">
      {menuItems.map((item, index) => (renderItem(item, index)))}
      {logged && (
        <LogoutOutlined
          alt="Sair"
          onClick={() => logoutRequest()}
          style={{
            color: '#fff',
            marginTop: 20,
            marginLeft: 20,
            cursor: 'pointer',
          }}
        />
      )}

    </Col>
  );
}

StandardNav.propTypes = {
  renderItem: PropTypes.func.isRequired,
  menuItems: PropTypes.array.isRequired,
  logoutRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  logged: PropTypes.bool,
};

StandardNav.defaultProps = {
  logged: false,
};

const mapStateToProps = (state) => ({
  loading: state.session.loading,
  user: state.session.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(SessionActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StandardNav);
