import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import history from '~/routes/history';
import createRootReducer from './ducks';
import sagas from './sagas';

const dev = process.env.NODE_ENV === 'development';

const sagaMonitor = dev ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [routerMiddleware(history), sagaMiddleware];

const config = dev
  ? compose(
    applyMiddleware(...middlewares),
    console.tron.createEnhancer(),
  )
  : applyMiddleware(...middlewares);

const store = createStore(createRootReducer(history), config);

sagaMiddleware.run(sagas);

export default store;
