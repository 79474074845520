import React from 'react';
import AboutCard from '~/components/AboutCard';
import people from '~/assets/img/people';

export function Rodrigo() {
  const body = (
    <div className="people">
      <p className="people-name">Rodrigo Gomes, MSc</p>
      <p className="people-description">Engenheiro da Computação (UPE) </p>
      <p className="people-description">Mestre em Engenharia Biomédica (UFPE)</p>
      <p className="people-description">Doutorando em Ciências da Computação (UFPE)</p>
    </div>
  );
  return (
    <AboutCard src={people.rodrigo} body={body} size="sm" />
  );
}
