import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'compose-function';
import { connect } from 'react-redux';
import {
  Row,
} from 'antd';
import { bindActionCreators } from 'redux';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import PageHeader from '~/components/PageHeader';
import { Creators as ConsultActions } from '~/redux/ducks/consult';
import { Creators as ReportActions } from '~/redux/ducks/report';
import Laudo from './components/laudo'

function Report({
  history
}) {
  const [menuItems] = useState([
    ['Início', '/app/home'],
    ['Exame', '/app/exam'],
    ['Laudo', '/app/report'],
    ['Histórico', '/app/historic'],
  ]);

  return (
    <div>
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div className='marginL-100' id='title-laudo'>
        <PageHeader title="Laudo" subtitle="O laudo do paciente foi montado, clique em imprimir ao fim da página." />
      </div>
      <Laudo/>
      <Row className="footer-exam">
        <PageFooter />
      </Row>
    </div>
  );
}

Report.propTypes = {
  loading: PropTypes.bool.isRequired,
  historicDataRequest: PropTypes.func.isRequired,
  reportDataRequest: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  data: PropTypes.object,
};

Report.defaultProps = {
  history: [],
  data: {},
};


const mapStateToProps = (state) => ({
  loading: state.consult.loading,
  data: state.consult.data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...ConsultActions,
  ...ReportActions,
}, dispatch);

export default compose(
  withTranslation(), connect(mapStateToProps, mapDispatchToProps),
)(Report);
