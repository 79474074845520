import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as ExamActions } from '~/redux/ducks/exam';

const PersonalData = ({ tempFirstStep }) => (
  <div className="session">
    <span className="visualize-session-title">Dados do Paciente</span>
    <Row className="marginL-5">
      <Col span={8}>
        <span className="visualize-title">Nome completo</span>
        <br />
        <span className="visualize-content">{tempFirstStep.firstName}</span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Sexo</span>
        <br />
        <span className="visualize-content">{tempFirstStep.gender || ''}</span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Cidade</span>
        <br />
        <span className="visualize-content">{tempFirstStep.city || ''}</span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">ID do Paciente</span>
        <br />
        <span className="visualize-content">{tempFirstStep.patientID || ''}</span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Data de Nascimento</span>
        <br />
        <span className="visualize-content">{new Date(tempFirstStep.birthDate).toLocaleDateString('pt-BR') || ''}</span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Estado</span>
        <br />
        <span className="visualize-content">{tempFirstStep.state || ''}</span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Telefone</span>
        <br />
        <span className="visualize-content">{tempFirstStep.phone || ''}</span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">CEP</span>
        <br />
        <span className="visualize-content">{tempFirstStep.zipCode || ''}</span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Endereço</span>
        <br />
        <span className="visualize-content">{tempFirstStep.street || ''}</span>
      </Col>
    </Row>
  </div>
);

PersonalData.propTypes = {
  tempFirstStep: PropTypes.object,
};

PersonalData.defaultProps = {
  tempFirstStep: {},
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempFirstStep: state.exam.tempFirstStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
