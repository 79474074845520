import React from 'react';
import { withTranslation } from 'react-i18next';
import PageHeader from '~/components/PageHeader';
import PersonalData from './components/personal-data';
import HemogramaData from './components/hemograma-data';
import AdditionalExams from './components/additional-exams';
import BloodGasAnalysis from './components/blood-gas-analysis';

const Visualize = () => (
  <div className="visualize-exam">
    <PageHeader title="Vamos conferir?" subtitle="Por favor, confira os dados para que possamos realizar o teste. Se necessário, você pode editálos e voltar aqui depois." />
    <PersonalData />
    <HemogramaData />
    <AdditionalExams />
    <BloodGasAnalysis />
  </div>
);

export default withTranslation()(Visualize);
