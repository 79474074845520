import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'antd';
import { Creators as ExamActions } from '~/redux/ducks/exam';

const BloodGasAnalysis = ({ tempFourthStep }) => (
  <div className="session">
    <span className="visualize-session-title">Dados de Gasometrias</span>
    <Row className="marginL-5">
      <Col span={8}>
        <span className="visualize-title">Saturação de Hemoglina Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.hb_saturation_arterial_blood_gases ? `${tempFourthStep.hb_saturation_arterial_blood_gases} mEc/L` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">CO2 Total no Sangue Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.total_co2_arterial_blood_gas_analysis ? `${tempFourthStep.total_co2_arterial_blood_gas_analysis} mmHg` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Excesso de Base (BE)</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.base_excess_arterial_blood_gas_analysis ? `${tempFourthStep.base_excess_arterial_blood_gas_analysis} mEc/L` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Pressão de O2 no Sangue Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.p_o2_arterial_blood_gas_analysis ? `${tempFourthStep.p_o2_arterial_blood_gas_analysis} mmHg` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">HCO3 Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.hco3_arterial_blood_gas_analysis ? `${tempFourthStep.hco3_arterial_blood_gas_analysis} mEc/L` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Fração de O2 Inspirado</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.arteiral_fio2 ? `${tempFourthStep.arteiral_fio2}%` : ' '}
        </span>
      </Col>
    </Row>
    <Row className="marginL-5 marginT-20">
      <Col span={8}>
        <span className="visualize-title">Pressão Parcial de CO2</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.p_co2_arterial_blood_gas_analysis ? `${tempFourthStep.p_co2_arterial_blood_gas_analysis} mmHg` : ' '}
        </span>
      </Col>
      <Col span={8} className="">
        <span className="visualize-title">Análise do pH do Sangue Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.p_h_arterial_blood_gas_analysis ? `${tempFourthStep.p_h_arterial_blood_gas_analysis}` : ' '}
        </span>
      </Col>
      <Col span={8}>
        <span className="visualize-title">Concetração Total de O2 Arterial</span>
        <br />
        <span className="visualize-content">
          {tempFourthStep.ct_o2_arterial_blood_gas_analysis ? `${tempFourthStep.ct_o2_arterial_blood_gas_analysis}%` : ' '}
        </span>
      </Col>
    </Row>
  </div>
);

BloodGasAnalysis.propTypes = {
  tempFourthStep: PropTypes.object,
};

BloodGasAnalysis.defaultProps = {
  tempFourthStep: {},
};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempFourthStep: state.exam.tempFourthStep,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BloodGasAnalysis);
