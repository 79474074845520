import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Steps, Row, Upload
} from 'antd';
import { connect } from 'react-redux';
import {
  LoadingOutlined, InboxOutlined
} from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import FirstStep from './steps/first-step';
import SecondStep from './steps/second-step';
import ThirdStep from './steps/third-step';
import FourthStep from './steps/fourth-step';
import Visualize from './visualize';
import { Creators as ExamActions } from '~/redux/ducks/exam';
import Notification from '~/components/Notification'
import PageHeader from '~/components/PageHeader';

function ExamPage({
  history,
  cadPatientRequest,
  loading,
  examRequest,
  tempFirstStep,
  tempSecondStep,
  tempThirdStep,
  tempFourthStep,
  uploadData,
  uploadExamRequest,
  secondStepRequest,
  thirdStepRequest,
  fourthStepRequest
}) {
  const [menuItems] = useState([
    ['Início', '/app/home'],
    ['Exame', '/app/exam'],
    ['Histórico', '/app/historic'],
  ]);

  const { Step } = Steps;

  const steps = [
    {
      title: 'Cadastro do Paciente',
    },
    {
      title: 'Escolher preenchimento',
    },
    {
      title: 'Hemograma Completo',
    },
    {
      title: 'Exames Sorológicos Adicionais',
    },
    {
      title: 'Gasometria',
    },
    {
      title: 'Confirmação de dados',
    },
  ];

  const [current, setCurrent] = useState(0);

  const next = () => {
    const nextStep = current + 1;
    window.scrollTo(0, 0);
    setCurrent(nextStep);
  };

  const prev = () => {
    const prevStep = current - 1;
    window.scrollTo(0, 0);
    setCurrent(prevStep);
  };

  const backToUpload = () => {
    window.scrollTo(0, 0);
    setCurrent(1);
  };

function endOfExam() {
  if( 
    tempFirstStep.firstName &&
    tempFirstStep.birthDate &&
    tempFirstStep.street &&
    tempFirstStep.zipCode &&
    tempFirstStep.city &&
    tempFirstStep.state &&
    tempFirstStep.phone &&
    tempFirstStep.gender != null) {
      cadPatientRequest(tempFirstStep);
      setTimeout(() => {
        examRequest({
          tempFirstStep, tempSecondStep, tempThirdStep, tempFourthStep,
        });
      }, 1000);
      history.push('result');
    }else{
      Notification({title: 'Erro', description: 'Por favor, volte ao cadastro de usuário e preencha todos os campos obrigatórios marcados com asterisco.', duration: 5, type: 'error'})
    }
}

const { Dragger } = Upload;
const [errorUpload, setErrorUpload] = useState(false)
const [errorKey, setErrorKey] = useState(false)

useEffect(() => {
  if(errorKey){
    Notification({title: 'Erro', description: 'O arquivo enviado é inválido!', duration: 4, type: 'error'})
  }
}, [errorKey])

useEffect(() => {
  if(errorUpload){
    Notification({title: 'Erro', description: 'Por favor, volte ao cadastro de usuário e preencha todos os campos obrigatórios marcados com asterisco.', duration: 5, type: 'error'})
  }
}, [errorUpload])

useEffect(() => {
  if(uploadData.blood_tests_result){
    if(uploadData.blood_tests_result.key !== 0){
      setErrorKey(true)
    }else{
      secondStepRequest(uploadData.blood_tests_result)
      thirdStepRequest(uploadData.blood_tests_result)
      fourthStepRequest(uploadData.blood_tests_result)
      setCurrent(5)
    }
  }
}, [uploadData, secondStepRequest, thirdStepRequest, fourthStepRequest])

const props = {
  name: 'file',
  multiple: false,
  accept: '.pdf',
  onChange(info) {
    if( 
      tempFirstStep.firstName &&
      tempFirstStep.birthDate &&
      tempFirstStep.street &&
      tempFirstStep.zipCode &&
      tempFirstStep.city &&
      tempFirstStep.state &&
      tempFirstStep.phone &&
      tempFirstStep.gender != null) {
      uploadExamRequest(info.file.originFileObj)
    }else{
      setErrorUpload(true)
    }
  },
};

  return (
    <div className="exam-container">
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div className="exam">
        <Steps current={current} className="step" labelPlacement="vertical">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        {!loading && current === 0 && <FirstStep />}
        {!loading && current === 1 && (
          <div className='visualize-exam'>
          <PageHeader
            title='Formas de preenchimento dos dados' 
            subtitle="Aqui você tem duas opções: ou você envia o PDF do exame agora ou clica no botão abaixo e preenche os dados manualmente." 
          />
          <div className='marginT-30 marginB-30' style={{flex: 1}}>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: '#399859', cursor: 'pointer', }} />
                </p>
                <p className="ant-upload-text">Enviar arquivo do exame agora</p>
                <p className="ant-upload-hint">
                Se você tiver esse exame em pdf, arraste-o para essa caixa.
                </p>
                <p className="ant-upload-hint">
                Atenção! Apenas um exame por vez.
                </p>
            </Dragger>
           </div>
        </div>
        )}
        {!loading && current === 2 && <SecondStep />}
        {!loading && current === 3 && <ThirdStep />}
        {!loading && current === 4 && <FourthStep />}
        {!loading && current === 5 && <Visualize />}
        {loading ? (<LoadingOutlined spin />) : (
          <div className="steps-action">
            {current > 0 && !uploadData.blood_tests_result && (
            <Button type="default" className="btn-default" style={{ margin: '0 8px' }} onClick={() => prev()}>
              Voltar
            </Button>
            )}
            {current > 0 && uploadData.blood_tests_result && (
            <Button type="default" className="btn-default" style={{ margin: '0 8px' }} onClick={() => backToUpload()}>
              Voltar
            </Button>
            )}
            {current === steps.length - 1 && (
            <Button type="default" className="btn-default" onClick={() => endOfExam()}>
              Finalizar
            </Button>
            )}
            {current < steps.length - 1 && current !== 1 && (
            <Button type="default" className="btn-default" onClick={() => next()}>
              Continuar
            </Button>
            )}
            {current === 1 && (
            <Button type="default" className="btn-white" onClick={() => next()}>
              Preencher manualmente
            </Button>
            )}
          </div>
        )}
      </div>
      <Row className="footer-exam">
        <PageFooter />
      </Row>
    </div>
  );
}

ExamPage.propTypes = {
  uploadExamRequest: PropTypes.func.isRequired,
  cadPatientRequest: PropTypes.func.isRequired,
  secondStepRequest: PropTypes.func.isRequired,
  thirdStepRequest: PropTypes.func.isRequired,
  fourthStepRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  examRequest: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  tempFirstStep: PropTypes.object,
  tempSecondStep: PropTypes.object,
  tempThirdStep: PropTypes.object,
  tempFourthStep: PropTypes.object,
  uploadData: PropTypes.object,
};

ExamPage.defaultProps = {
  history: [],
  tempFirstStep: {},
  tempSecondStep: {},
  tempThirdStep: {},
  tempFourthStep: {},
  uploadData: {}
};


const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempFirstStep: state.exam.tempFirstStep,
  tempSecondStep: state.exam.tempSecondStep,
  tempThirdStep: state.exam.tempThirdStep,
  tempFourthStep: state.exam.tempFourthStep,
  examResult: state.exam.examResult,
  uploadData: state.exam.uploadData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExamPage);
