import { all, takeLatest } from 'redux-saga/effects';

import { Types as SessionTypes } from '../ducks/session';
import { Types as ConsultTypes } from '../ducks/consult';
import { Types as ExamTypes } from '../ducks/exam';
import { Types as ReportTypes } from '../ducks/report';
import { Types as UserTypes } from '../ducks/user';

import {
  login, userInfo, logout, clearSessions,
} from './session';
import { historic } from './consult';
import { report } from './report';
import { userRegister } from './user';
import {
  cadPatient,
  makeExam,
  firstStep,
  secondStep,
  thirdStep,
  fourthStep,
  uploadExam
} from './exam';

export default function* rootSaga() {
  yield all([

    takeLatest(SessionTypes.LOGIN_REQUEST, login),
    takeLatest(SessionTypes.USER_REQUEST, userInfo),
    takeLatest(SessionTypes.LOGOUT_REQUEST, logout),
    takeLatest(SessionTypes.CLEAR_USER_SESSION, clearSessions),

    takeLatest(ExamTypes.CAD_PATIENT_REQUEST, cadPatient),
    takeLatest(ExamTypes.EXAM_REQUEST, makeExam),
    takeLatest(ExamTypes.TEMP_FIRST_STEP_EXAM_REQUEST, firstStep),
    takeLatest(ExamTypes.TEMP_SECOND_STEP_EXAM_REQUEST, secondStep),
    takeLatest(ExamTypes.TEMP_THIRD_STEP_EXAM_REQUEST, thirdStep),
    takeLatest(ExamTypes.TEMP_FOURTH_STEP_EXAM_REQUEST, fourthStep),
    takeLatest(ExamTypes.UPLOAD_EXAM_REQUEST, uploadExam),

    takeLatest(ConsultTypes.HISTORIC_DATA_REQUEST, historic),

    takeLatest(ReportTypes.REPORT_DATA_REQUEST, report),

    takeLatest(UserTypes.USER_REGISTER_REQUEST, userRegister),

  ]);
}
