export const Types = {
  REPORT_DATA_REQUEST: 'REPORT_DATA_REQUEST',
  REPORT_DATA_SUCCESS: 'REPORT_DATA_SUCCESS',
  REPORT_DATA_FAILURE: 'REPORT_DATA_FAILURE',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export default function Report(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REPORT_DATA_REQUEST:
      return { ...state, loading: true };
    case Types.REPORT_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.REPORT_DATA_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  reportDataRequest: (payload) => ({
    type: Types.REPORT_DATA_REQUEST,
    payload,
  }),

  reportDataSuccess: (payload) => ({
    type: Types.REPORT_DATA_SUCCESS,
    payload,
  }),

  reportDataFailure: (reason) => ({
    type: Types.REPORT_DATA_FAILURE,
    payload: reason,
  }),
};
