import React, { useState, useEffect } from 'react';
import {
  Select, DatePicker, Col, Row, Input,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Creators as ExamActions } from '~/redux/ducks/exam';
import MaskedInput from 'antd-mask-input'
import Notification from '~/components/Notification'

const INVALID_ZIP_CODE = 'Não foi possível encontrar um endereço com esse CEP. Verifique o valor digitado.';

function FirstStep({ firstStepRequest, tempFirstStep }) {
  const { Option } = Select;

  const tempGender = () => {
    let temp = null
    if(tempFirstStep.gender){
      temp = (tempFirstStep.gender === 'Feminino' ? 0 : 1)
    }
    return temp
  }

  const [firstName, setFirstName] = useState(tempFirstStep.firstName);
  const [patientID, setPatientID] = useState(tempFirstStep.patientID);
  const [phone, setPhone] = useState(tempFirstStep.phone);
  const [birthDate, setBirthDate] = useState(tempFirstStep.birthDate || null);
  const [zipCode, setZipcode] = useState(tempFirstStep.zipCode);
  const [city, setCity] = useState(tempFirstStep.city);
  const [state, setState] = useState(tempFirstStep.state);
  const [street, setStreet] = useState(tempFirstStep.street);
  const [genderNumber, setGenderNumber] = useState(tempGender);
  const [addressNumber, setAddressNumber] = useState('');
  const [addressAdjunct, setAddressAdjunct] = useState('');

  useEffect(() => {
    firstStepRequest({
      firstName,
      patientID,
      phone,
      gender: genderNumber === 0 ? 'Feminino' : 'Masculino',
      birthDate,
      zipCode,
      city,
      state,
      street,
      addressNumber,
      addressAdjunct
    });
  }, [
    firstName,
    patientID,
    phone,
    genderNumber,
    birthDate,
    zipCode,
    city,
    state,
    street,
    addressNumber,
    addressAdjunct,
    firstStepRequest]);

    const zipCodeChange = (value) => {
      if (!value) return;
      const CEP = value.replace(/\D/g, '');
      setZipcode(CEP);
      if (CEP.length === 8) {
        fetch(`https://viacep.com.br/ws/${CEP}/json/`)
          .then((response) => response.json())
          .then((json) => {
            if (json.erro) {
              setZipcode(null)
              setStreet('')
              setCity('')
              setAddressAdjunct('')
              setState('')
              Notification({title: 'Atenção!', description: INVALID_ZIP_CODE, duration: 5, type: 'warning'})
              return;
            }
            setStreet(json.logradouro ? `${json.logradouro}, ${json.bairro}` : '')
            setCity(json.localidade)
            setAddressAdjunct(json.complemento)
            setState(json.uf)
          });
      } else {
        setZipcode(null)
        setStreet('')
        setCity('')
        setAddressAdjunct('')
        setState('')
      }
    };

  return (
    <div className="form-exam marginT-50">
      <div className="field">
        Nome completo *
        <Input
          placeholder=""
          style={{width: 422}}
          className="ant-input"
          value={firstName}
          required
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <Row>
      <Col span={12}>
          ID do Paciente *
          <input
            placeholder=""
            className="ant-input"
            type="text" 
            maxlength="9"
            pattern="([0-9]{9})"
            value={patientID}
            onChange={(inputValue) => setPatientID(inputValue.target.value)}
          />
        </Col>
        <Col span={11} className='marginL-10'>
          Telefone *
          <MaskedInput
            placeholder=""
            mask='(11)1 1111-1111'
            className="ant-input"
            value={phone}
            onChange={(inputValue) => setPhone(inputValue.target.value)}
          />
        </Col>
      </Row>
      <Row className="marginT-30">
        <Col span={12}>
          <div className="field">
            Sexo *
            <Select
              defaultValue="Selecione"
              style={{ width: 120 }}
              value={genderNumber}
              onChange={(item) => setGenderNumber(item)}
            >
              <Option value={0}>Feminimo</Option>
              <Option value={1}>Masculino</Option>
            </Select>
          </div>
        </Col>
        <Col span={12}>
        Data de nascimento *
          <DatePicker
            minDate={new Date()}
            placeholder="DD/MM/AAAA"
            format="L"
            className="small-input"
            onChange={(item) => setBirthDate(item)}
            value={birthDate}
          />
        </Col>
      </Row>

      <Row>
        <div className="field">
        CEP *
          <MaskedInput
            placeholder=""
            mask='11111-111'
            className="ant-input"
            value={zipCode}
            onChange={(inputValue) => setZipcode(inputValue.target.value)}
            onBlur={(inputVal) => zipCodeChange(inputVal.target.value)}
          />
        </div>
        <Col span={19}>
          <div className="field">
        Cidade *
            <Input
              placeholder=""
              className="ant-input md-input"
              value={city}
              readOnly
              onChange={(inputValue) => setCity(inputValue.target.value)}
            />
          </div>
        </Col>
        <Col span={5}>
          <div className="field marginL-20">
            <span>UF *</span>
            <Input
              placeholder=""
              maxLength={2}
              readOnly
              className="ant-input"
              value={state}
              onChange={(inputValue) => setState(inputValue.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={19}>
          <div className="field">
          Endereço *
          <Input
            placeholder=""
            className="ant-input"
            value={street}
            onChange={(inputValue) => setStreet(inputValue.target.value)}
          />
        </div>
        </Col>
        <Col span={5}>
          <div className="field marginL-10">
            <span>Num.</span>
            <Input
              placeholder=""
              className="ant-input"
              value={addressNumber}
              type='number'
            onChange={(inputValue) => setAddressNumber(inputValue.target.value)}
            />
          </div>
        </Col>
        <Col span={24}>
          <div className="field">
            <span>Complemento</span>
            <input
              placeholder=""
              className="ant-input"
              value={addressAdjunct}
              onChange={(inputValue) => setAddressAdjunct(inputValue.target.value)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

FirstStep.propTypes = {
  cadPatientRequest: PropTypes.func.isRequired,
  firstStepRequest: PropTypes.func.isRequired,
  tempFirstStep: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  loading: state.exam.loading,
  tempFirstStep: state.exam.tempFirstStep,
  patient: state.exam.patient,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(ExamActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);
