export const Types = {
  HISTORIC_DATA_REQUEST: 'HISTORIC_DATA_REQUEST',
  HISTORIC_DATA_SUCCESS: 'HISTORIC_DATA_SUCCESS',
  HISTORIC_DATA_FAILURE: 'HISTORIC_DATA_FAILURE',
};

const INITIAL_STATE = {
  data: {},
  loading: false,
};

export default function Consult(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.HISTORIC_DATA_REQUEST:
      return { ...state, loading: true };
    case Types.HISTORIC_DATA_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.HISTORIC_DATA_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  historicDataRequest: (payload) => ({
    type: Types.HISTORIC_DATA_REQUEST,
    payload,
  }),

  historicDataSuccess: (payload) => ({
    type: Types.HISTORIC_DATA_SUCCESS,
    payload,
  }),

  historicDataFailure: (reason) => ({
    type: Types.HISTORIC_DATA_FAILURE,
    payload: reason,
  }),
};
