import PropTypes from 'prop-types';
import { notification } from 'antd';

const Notification = ({ title, description, duration, type }) => {
    const args = {
        message: title,
        description: description,
        duration: duration,
    };

    switch (type) {
        case ('success'):
            return notification.success(args)
        case ('error'):
            return notification.error(args)
        case ('info'):
            return notification.info(args)
        case ('warning'):
            return notification.warning(args)
        default:
            return notification.open(args);
    }
};

Notification.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    type: PropTypes.string,
};

Notification.defaultProps = {
    title: '',
    description: '',
    duration: 2,
    type: 'info',
};

export default Notification;
