import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import compose from 'compose-function';
import Icon from '@mdi/react';
import { mdiDownload } from '@mdi/js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Table, Modal, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import TopNav from '~/containers/TopNav';
import Logo from '~/assets/img/HEG.IA.png';
import PageFooter from '~/components/PageFooter';
import PageHeader from '~/components/PageHeader';
import { Creators as ConsultActions } from '~/redux/ducks/consult';
import { Creators as ReportActions } from '~/redux/ducks/report';
import Notification from '~/components/Notification'

function HistoricPage({
  t, history, historicDataRequest, reportDataRequest, data, loading,
}) {
  const [menuItems] = useState([
    [`${t('start')}`, '/app/home'],
    [`${t('Exame')}`, '/app/exam'],
    [`${t('Histórico')}`, '/app/historic'],
  ]);

  const [modalVisible, setModalVisible] = useState(false);
  const [doctorName, setDoctorName] = useState('');
  const [doctorRegister, setDoctorRegister] = useState('');
  const [observations, setObservations] = useState('');
  const [outputID, setOutputID] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    historicDataRequest();
  }, [historicDataRequest]);

  let tableInfo = [];
  if (data) tableInfo = Object.values(data);

  const listTableInfo = [];
  tableInfo.forEach((item, index) => {

    const temp = {
      internID: item.output_covid_result.id,
      key: index,
      id: item.user.patient_id,
      name: item.user.first_name,
      city: item.user.city,
      intensive_care: item.output_covid_result.intensive_care === true ? 'Sim' : 'Não',
      regular_ward: item.output_covid_result.regular_ward === true ? 'Sim' : 'Não',
      sars_cov_2: item.output_covid_result.sars_cov_2 === true ? 'Sim' : 'Não',
      semi_intensive_care: item.output_covid_result.semi_intensive_care === true ? 'Sim' : 'Não',
    };

    listTableInfo.push(temp);
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  let searchInput = null;
  const getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Buscar por ${label}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div className='btn-search-screen' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, alignItems: 'center', justifyContent: 'center' }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpar
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id', 'id'),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name', 'nome')
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      key: 'city',
      ...getColumnSearchProps('city', 'cidade')
    },
    {           
      title: 'COVID-19',
      dataIndex: 'sars_cov_2',
      key: 'sars_cov_2',
        filters: [
          {
            text: 'Sim',
            value: 'Sim',
          },
          {
            text: 'Não',
            value: 'Não',
          },
        ],
      onFilter: (value, record) => record.sars_cov_2.indexOf(value) === 0,
      filterMultiple: false,
    },
    {
      title: 'Atendimento Regular',
      dataIndex: 'regular_ward',
      key: 'regular_ward',
      filters: [
        {
          text: 'Sim',
          value: 'Sim',
        },
        {
          text: 'Não',
          value: 'Não',
        },
      ],
      onFilter: (value, record) => record.regular_ward.indexOf(value) === 0,
      filterMultiple: false,
    },
    {
      title: 'Unidade Semi Intensiva de Tratamento',
      dataIndex: 'semi_intensive_care',
      key: 'semi_intensive_care',
      filters: [
        {
          text: 'Sim',
          value: 'Sim',
        },
        {
          text: 'Não',
          value: 'Não',
        },
      ],
      onFilter: (value, record) => record.semi_intensive_care.indexOf(value) === 0,
      filterMultiple: false,
    },
    {
      title: 'Unidade Intensiva de Tratamento (UTI)',
      dataIndex: 'intensive_care',
      key: 'intensive_care',
      filters: [
        {
          text: 'Sim',
          value: 'Sim',
        },
        {
          text: 'Não',
          value: 'Não',
        },
      ],
      onFilter: (value, record) => record.intensive_care.indexOf(value) === 0,
      filterMultiple: false,
    },
    {
      title: 'Gerar Laudo',
      key: 'blood_test_result_id',
      dataIndex: 'blood_test_result_id',
      render: () => (
        <div onClick={(item) => setModalVisible(true)}>
          <Icon
            path={mdiDownload}
            size={1}
            color="#30C060"
            className="pointer"
          />
        </div>
      ),
    },
  ];


  const { TextArea } = Input;

  const sendReportRequest = () => {
    if(doctorName && doctorRegister && outputID){
      setModalVisible(false);
      reportDataRequest({
        doctor_name: doctorName,
        doctor_professional_id: doctorRegister,
        output_covid_result_id: outputID,
        remark: observations,
      });
      history.push('report')
    }else{
      Notification({title: 'Erro', description: 'Por favor, preencha todos os campos obrigatórios marcados com asterisco.', duration: 3, type: 'error'})
    }
  };

  return (
    <div>
      <main>
        <TopNav
          className="navbar-logged"
          logo={Logo}
          menuItems={menuItems}
          history={history}
          logged
        />
      </main>
      <div className='marginL-100'>
        <PageHeader title="Histórico" subtitle="Aqui estão registrados todos os exames realizados por você." />
      </div>
      <div className="historic">
        <Table
          pagination
          showHeader
          tableLayout="auto"
          rowClassName="center"
          rowKey={(item) => item.key}
          key="table"
          onRowClick={(item) => setOutputID(item.internID)}
          className="center"
          dataSource={listTableInfo}
          columns={columns}
          loading={loading}
          bordered          
        />
        <Modal
          title="Informações adicionais"
          visible={modalVisible}
          onOk={() => sendReportRequest()}
          onCancel={() => setModalVisible(false)}
          className="laudo-modal"
        >
          <div className="container-laudo">
            <span className="text">Nome do Médico *</span>
            <Input value={doctorName} onChange={(inputValue) => setDoctorName(inputValue.target.value)} />
          </div>
          <div className="container-laudo">
            <span className="text">Registro do Médico *</span>
            <Input value={doctorRegister} onChange={(inputValue) => setDoctorRegister(inputValue.target.value)} />
          </div>
          <div className="container-laudo">
            <span className="text">Observações</span>
            <TextArea rows={3} value={observations} onChange={(inputValue) => setObservations(inputValue.target.value)} />
          </div>
        </Modal>
        <PageFooter type='flex' />
      </div>
    </div>
  );
}

HistoricPage.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  historicDataRequest: PropTypes.func.isRequired,
  reportDataRequest: PropTypes.func.isRequired,
  history: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  data: PropTypes.object,
};

HistoricPage.defaultProps = {
  history: [],
  data: {},
};


const mapStateToProps = (state) => ({
  loading: state.consult.loading,
  data: state.consult.data,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...ConsultActions,
  ...ReportActions,
}, dispatch);

export default compose(
  withTranslation(), connect(mapStateToProps, mapDispatchToProps),
)(HistoricPage);
